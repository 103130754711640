import React, { Fragment, useState, useEffect } from "react";
import "./Review.css";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import Alert from "@mui/material/Alert";

const Review = () => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    overflow: "hidden",
  };

  const [open, setOpen] = useState(false);
  const params = useParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [rate, setRate] = useState("");
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [alert, setAlert] = useState(null);
  const [alertFail, setAlertFail] = useState(null);

  const [review, setReview] = useState([]);
  const [filterReview,setFilterReview] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [averageRating, setAverageRating] = useState(0);


  const onHandleClickPost = async () => {
    try {
      if (rate && name) {
        const res = await axios.post("/api/bookreview", {
          bookId: params.id,
          review: details,
          name: name,
          ratings: rate,
        });
        console.log(res);
        if (res && res.status === 200) {
          // getBookDetails(id);
          setAlert(res.data.message);
          setTimeout(() => {
            setAlert(null);
            handleClose();
          }, 2000);
          setDetails("");
          setName("");
          setRate("");
        } else {
          setAlertFail(res.data.message);
          setTimeout(() => {
            setAlertFail(null);
          }, 2000);
        }
      } else if (name === "") {
        setAlertFail("Please Enter Your Name");
        setTimeout(() => {
          setAlertFail(null);
        }, 2000);
      } else {
        setAlertFail("Please Enter Your Rating");
        setTimeout(() => {
          setAlertFail(null);
        }, 2000);
      }
    } catch (error) {
      setAlertFail(error.response.data.message);
      setTimeout(() => {
        setAlertFail(null);
      }, 2000);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/api/getallreview/${params.id}`);
        setReview(res.data);

        const filteredReviews = res.data.review.filter(review => review.mode);

        setFilterReview(filteredReviews)

        setReviewCount(filteredReviews.length);

        const totalRating = filteredReviews.reduce((acc, curr) => acc + parseFloat(curr.rating), 0);
        const avgRating = filteredReviews.length > 0 ? totalRating / filteredReviews.length : 0;
        setAverageRating(avgRating);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
 

  return (
    <div className="reviews-Maincontainer">
      <div className="title">Customer Reviews</div>
      <div className="book-review">
        <div className="book-image-div">
          {/* <img src={review.image1} /> */}
          <img
            src={
              review.images && review.images.length > 0 ? review.images[0] : ""
            }
            alt=""
          />
        </div>
        <div className="book-review-details">
          <div className="book-rating">
            {" "}
            {filterReview &&
              filterReview.length > 0 &&
              averageRating.toFixed(1) + "out of 5"}{" "}
          </div>

          <div className="star-rating">
            <div>
              {filterReview && filterReview.length > 0 && (
                <Rating
                  name="simple-controlled"
                  precision={0.5}
                  value={averageRating}
                  readOnly
                />
              )}{" "}
            </div>

            <div className="small-text">
              {filterReview &&
                filterReview.length > 0 &&
                averageRating.toFixed(1) + "Ratings"}
            </div>
          </div>
          <button className="btn-review" onClick={handleOpen}>
            Write a review
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            className="write-review-modal-cusotm-class"
          >
            <Box sx={modalStyle}>
              {alert && (
                <Alert severity="success" variant="filled">
                  <strong>{alert}</strong>
                </Alert>
              )}
              {alertFail && (
                <Alert severity="error" variant="filled">
                  <strong>{alertFail}</strong>
                </Alert>
              )}
              <div className="modal-header">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABHNCSVQICAgIfAhkiAAAAjpJREFUWEfNmIFVgzAQhpvnAHaEOoF2AusE2glsN9AJrBOoE0gn0A2sE2gn0BHsBPj/vDteCEmBEqB57x4hPcjHf8lx1Iw6bGmajnH7W9gNbCZT/eH4DlsbYzbu9KYrHsAQ4hVGqFAj0BxghMxaJ0CAWQiMzrNG51uMShH23FLsTKGiA3lgppiMMIUmfs8YPCUofKbRFfLAcA7CXNlhUTIJ65ucL+GTRFPIgdlikgT2JJPtg9rA5xL2CaBZFCAPzIyKOONeKNsH15jWQCEYKyz2Ai9B4Xou8g/xP2kFVAXDSSQXfaE78YXPBmqlUAMYPv2Fs8lypXCfFX57gO0AND5IoQNhlgLFZKm7b44O1WPyZOZeNAY6FIZbWkJorylmaM3kTI6/jYDawgQWOodfAHPHTm2gWDCiEsNGpdiyUClsLaC+YGop1CdMJVDfMHuBhoAJAg0F4wUaEqYENDRMAegYYHKgY4GxgVLNlDgmyJz6IsyGpYSw39pZuWldo37BDOz6hs6NUyCpXw7VJ0ymkAPEzxV+2LFRgXtYL8rk7zK3YsM5QRTKLg86C5MdPiq0wgArtpHW2A4Uf+oFRkOmQNlniNI6UL6F3noB+xa2rVABiM4hKIx3AqMK8Z+Ia1gJyAclT7WQY6G48j1x0zEqtMFF/HJ8RMgYvlLzrCn6RIdRhRSISrl/CrAA3zIJOlCdwCjQDzqTPdLmygkUd6OGrGlEKv0ZMvu1sfOoxB2WVN4pkkOtIj/SXLVu8w+k7d8zcapEEgAAAABJRU5ErkJggg=="
                  className="modal-icon"
                />
                Rate and Review
              </div>
              <div className="modal-contents">
                <div className="modal-title-container">
                  <div className="modal-img-container">
                    {/* <img src={review.image1} className="modal-img" /> */}
                    <img
                      className="modal-img"
                      src={
                        review.images && review.images.length > 0
                          ? review.images[0]
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div className="modal-des-container">
                    <div className="modal-title">{review.name}</div>
                    <div className="modal-sub-title">
                      Your review will be posted publicly on the web
                    </div>
                    <div className="modal-sub-title">
                      <label className="label">
                        Your Ratings<span style={{ color: "red" }}> * </span>
                      </label>
                      <Rating
                        name="simple-controlled"
                        value={Number(rate)}
                        precision={0.5}
                        onChange={(event, newValue) => {
                          setRate(newValue);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <input
                  type="name"
                  placeholder="Your Name *"
                  className="modal-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <textarea
                  type="textarea"
                  name="details"
                  className="modal-textarea"
                  placeholder="Share details of your own experience at this place"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />

                <div className="modal-footer">
                  <button
                    className="modal-close-post"
                    onClick={onHandleClickPost}
                  >
                    Add Review
                  </button>
                  <button className="modal-close-btn" onClick={handleClose}>
                    CANCEL
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      {filterReview && filterReview.length > 0 && (
        <hr className="divider-solid" />
      )}
      <div className="filter-div">
        {filterReview && filterReview.length > 0 && (
          <div className="review-count">
            {"Showing " + reviewCount + " of  "} {reviewCount + " Reviews"}{" "}
          </div>
        )}
      </div>
      <Fragment>
        {filterReview && filterReview.length > 0 && (
          <hr className="divider-solid" />
        )}
        <div className="amazing-reviews">
          {filterReview &&
            filterReview.map((data, index) => (
              <Fragment>
               <div className="review-container">
                  <div className="reviewer-image">
                    <div className="reviewer-profile">
                      {data.username ? data.username.charAt(0) : "N"}
                    </div>
                  </div>
                  <div className="review-container-details">
                    <div className="review-container-rating-details">
                      {data.rating && (
                        <Rating
                          name="simple-controlled"
                          precision={0.5}
                          value={data.rating}
                          readOnly
                        />
                      )}
                      <div className="review-name">
                        By {data.username} {data.date.substr(0, 10)}
                      </div>
                    </div>
                    <div className="review-description">{data.description}</div>
                  </div>
                </div>
                <hr className="divider-solid-fill" />
              </Fragment>
            ))}
        </div>
      </Fragment>
    </div>
  );
};

export default Review;
