import "./banner.css";
import { Carousel } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import { trackMetaPixelEvent } from "../../tracking/FacebookPixelEvent";

const Banner = () => {
  const navigate = useNavigate();
  const [datas, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get("/api/getbanner");
        setData(res.data);// trackMetaPixelEvent('BannerImageClicked', { button: 'banner click' });
        setLoading(false);// trackMetaPixelEvent('BannerImageClicked', { button: 'banner click' });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  const BannerSkeleton = () => (
    <div className="banner-skeleton">
      <div className="banner-skeleton-image"></div>
    </div>
  );

  const handleBannerClick = (url) => {  
    // trackMetaPixelEvent('BannerImageClicked', { button: 'banner click' });
    navigate(url);
  };

  return (
    <>
      {loading ? (
        <BannerSkeleton />
      ) : (
        <Carousel className="banner-container" >
          {datas &&
            datas.map((val, index) => (
              <Carousel.Item key={index} interval={5000}>
                <div
                  className="banner-image"
                  onClick={() => handleBannerClick(val.url)}
                >
                  <img
                    src={val?.image}
                    alt="Banner slide"
                    className="banner-img"
                  />
                </div>
              </Carousel.Item>
            ))}
        </Carousel>
      )}
    </>
  );
};

export default Banner;
