// import React from "react";
// import "./sidebar.css";
// import { useNavigate } from "react-router-dom";
// import axios from "../../../axios";

// const Sidebar = () => {
//   const navigate = useNavigate();

//   const logout = async () => { 
//     try{
//       const res = await axios.get('/api/logout',{withCredentials:true})
//         localStorage.removeItem("userid")
//         navigate("/")
//     }catch(error){
//       console.log(error)
//     }
//   };

//   return (
//     <div className="sidebar-container">
//       <div className="sidebar-box">
//         <text className="sidebar-title">MY ACCOUNT</text>
//         <div className="sidebar-desc">
//           <text onClick={() => navigate("/accounts/profile")}>Profile</text>
//           <text onClick={() => navigate("/accounts/orders")}>Orders</text>
//           <text onClick={() => navigate("/accounts/address")}>Address</text>
//           <text onClick={logout}>Logout</text>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;


import React, { useState } from "react";
import "./sidebar.css";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios";

const Sidebar = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const logout = async () => {
    try {
      const res = await axios.get('/api/logout', { withCredentials: true });
      localStorage.removeItem("userid");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogoutClick = () => {
    setShowPopup(true);
  };

  const confirmLogout = () => {
    setShowPopup(false);
    logout();
  };

  const cancelLogout = () => {
    setShowPopup(false);
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-box">
        <text className="sidebar-title">MY ACCOUNT</text>
        <div className="sidebar-desc">
          <text onClick={() => navigate("/accounts/profile")}>Profile</text>
          <text onClick={() => navigate("/accounts/orders")}>Orders</text>
          <text onClick={() => navigate("/accounts/address")}>Address</text>
          <text onClick={handleLogoutClick}>Logout</text>
        </div>
      </div>
      {showPopup && (
        <div className="popup-container">
          <div className="popup-box">
            <p>Are you sure you want to logout?</p>
            <button onClick={confirmLogout}>Yes</button>
            <button onClick={cancelLogout}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
