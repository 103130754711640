import React from 'react'
import Addressforms from '../../shipping/addressform/Addressform'

const Addressform = () => {
  return (
        <div className='addressforms-box'>
            <Addressforms  type="accounts" />
            </div>
          
  )
}

export default Addressform