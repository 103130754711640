import React from 'react'
import Addcatagorymngs from '../../component/admin/catagorymng/Addcatagorymng'
import Navbar from '../../component/admin/navbar/Navbar'

const Addcatagorymng = () => {
  return (
    <div>
        <Navbar />
        <Addcatagorymngs />
    </div>
  )
}

export default Addcatagorymng