import React from 'react'
import Navbar from '../../component/admin/navbar/Navbar'
import Editproducts from '../../component/admin/productmng/Editproduct'

const Editproduct = () => {
  return (
    <div>
      <Navbar />
      <Editproducts />
    </div>
  )
}

export default Editproduct
