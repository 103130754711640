import React from 'react'
import Orderdetails from '../../component/admin/ordermng/orderdetail/Orderdetail'
import Navbar from '../../component/admin/navbar/Navbar'

const Orderdetail = () => {
  return (
    <div>
        <Navbar />
        <Orderdetails />
    </div>
  )
}

export default Orderdetail