import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./catagorymng.css";
import axios from "../../../axios";

const Showcatagorymng = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get("/api/admin/getcatagory", {withCredentials:true});
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refresh]);

  const deletecatagory = async (id) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this category?"
    );

    try {
      if (confirmed) {
        let res = await axios.delete(`/api/admin/deletecatagory/${id}`, {withCredentials:true});
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editcatagory = (id) => {
    navigate(`/admin/editcatagory/${id}`);
  };

  return (
    <div className="showcatagory-container">
      <div className="showcatagory-box">
        <div
          className="showcatagory-addproduct-btn"
          onClick={() => navigate("/admin/addcatagory")}
        >
          <button>Add Catagory</button>
        </div>
        <h1 className="showcatagory-title">CATAGORY</h1>

        <div className="showcatagory-divtable">
          <table className="showcatagory-table">
            <tr>
              <th>Image</th>
              <th>Catagory Banner</th>
              <th>Catagory Name</th>
              <th>Action</th>
            </tr>
            {data.map((item) => (
              <tr>
                <td className="showcatagory-img-container">
                  <img
                    className="showcatagory-img"
                    src={item.image}
                    alt="img"
                  />
                </td>
                <td className="showcatagory-img-container">
                  {item?.catbanner && <img
                    className="showcatagory-img"
                    src={item?.catbanner}
                    alt="img"
                  />}
                </td>
                <td>{item.name}</td>
                <td>
                  <button
                    value={item._id}
                    onClick={(e) => editcatagory(e.target.value)}
                  >
                    Edit
                  </button>
                  <button
                    value={item._id}
                    onClick={(e) => deletecatagory(e.target.value)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Showcatagorymng;
