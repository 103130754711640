import React, { useEffect } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import LoadingShimmer from "../admin/loadingshimmer/LoadingShimmer";
// import Google from "../Google/Google";
import Otp from "./otp/Otp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

const Login = () => {
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
const returnUrl = params.get('returnUrl') || '/'; 

  const { useState } = React;

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mobNumber, setMobNumber] = useState("");
  const [showOtpFields, setShowOtpFields] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));

  const [password,setPassword] = useState("")

  const login = async () => {
    try {
      var regex = /^[0-9]{10}$/;
      const validate = regex.test(mobNumber);
      if (validate) {
        let res = await axios.post("/otp/otplogin", { mobNumber });
        toast.success(res.data.message);
        setShowOtpFields(true); 
      } else {
        setErrorMessage("Enter valid number");
      }
    } catch (error) {
      console.log(error.response.data.code);
      if(error.response.data.code === 60410){
        toast.error("Number have been blocked.");
      }else if(error.response.data.code === 21608){
        toast.error(" The phone number is unverified");
      }else{
        toast.error("Internal server error");
      }
    } finally {
      setIsLoading(false);
    }
  }; 

  // const login = async () => {
  //   try{
  //           var regex = /^[0-9]{10}$/;
  //     const validate = regex.test(mobNumber);
  //     if(validate){
  //       setErrorMessage("");
  //       let res = await axios.post("/otp/otplogin", { mobNumber,password },{withCredentials: true});
        
  //       localStorage.setItem("userid",res.data._id)
  //       navigate("/")
  //     } else {
  //             setErrorMessage("Enter valid number");
  //          }
  //   }catch(error){
  //     toast.error(error.response.data.message)
  //   }
  // }

  const verifyOtp = async () => {
    setIsLoading(true);
    const cartId = Cookies.get('cartId');
    try {
      const otpValue = otp.join(""); 
      let res = await axios.post("/otp/verifyotp", {
        mobNumber,
        otp: otpValue,
        cartId
      },{
        withCredentials: true
      });
      if(res.data._id){
        toast.success(res.data.message);
        localStorage.setItem("userid",res.data._id);
        Cookies.remove('cartId');
        navigate(returnUrl);
      }
    } catch (error) {
      console.log(error);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <ToastContainer />
      <div className="container">
        <div className="card">
          <div className="form">
            <div className="left-side">
              <div className="left-side-text">
                <h1>Hello!</h1>
                <p>
                  Enter your Mobile Number or login with email to start the
                  journey with us
                </p>
                {/* <button onClick={()=> navigate('/signup')}>
                SIGN UP
              </button> */}
              </div>
            </div>
            <div className="right-side">
              <div className="heading">
                <h3>Log in to Inkista.</h3>
                <p>
                  Welcome Back! login with your data that you entered during
                  registration.
                </p>
              </div>
              <form>
                <p className="signin-error">{errorMessage}</p>
                <div className="input-text">
                <text className="input-mobtext">Mobile Number</text>
                  <input
                    type="number"
                    className="wemail"
                    onChange={(e) => setMobNumber(e.target.value)}
                    disabled={showOtpFields} 
                    placeholder="Enter number"
                  />
                  <i className="fa fa-mobile"></i>
                </div>

{/* test code */}
                {/* <div className="input-text">
                <text className="input-mobtext">Password</text>
                  <input
                    type="password"
                    className="wemail"
                    onChange={(e) => setPassword(e.target.value)}
                    // disabled={showOtpFields}
                    placeholder="Enter password"
                  />
                </div> */}


                {/* test code */} 
                {/* <button type="button" onClick={login}>
                      Login With OTP
                    </button> */}

                {showOtpFields && <Otp otp={otp} setOtp={setOtp}/>} 
                <div className="button">
                  {!showOtpFields ? (
                    <button type="button" onClick={login}  disabled={isLoading}>
                       {isLoading ? "Loading..." : "Login With OTP"}
                    </button>
                  ) : (
                    <button type="button" onClick={verifyOtp}  disabled={isLoading}>
                      {isLoading ? "Verifying..." : "Verify OTP"}
                    </button>
                  )}
                </div>
              </form>

              {/* <div className="login-or">
                <text>OR</text>
              </div> */}

              {/* <div className="">
                <Google />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="loading-shimmer-add-product">
        {isLoading && (
          <div className="loading-shimmer">
            <LoadingShimmer />
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
