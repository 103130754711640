import axios from '../../../axios';
import React, { useEffect, useState } from 'react';
import './orderdetail.css';
import { useParams,useNavigate } from 'react-router-dom';
import moment from 'moment';

const Orderdetail = () => {

  const params = useParams();
  const navigate = useNavigate();

  const [data,setData] = useState();
  const [refresh,setRefresh] = useState(true)
  const [cancellationReason, setCancellationReason] = useState('');
  const [showCancelBox, setShowCancelBox] = useState(false);
  const [error, setError] = useState('');


  useEffect(()=>{
    (async()=>{
    try{
      let res = await axios.get(`/api/order/detail/${params.id}`,{withCredentials:true})
      setData(res.data)
    }catch(error){
      console.log(error)
    }
    })()
  },[refresh])


  const amount = data?.total+data?.shippingCharge;
  const orderid = data?._id;
  const products = data?.products;
 

  const handleCancelClick = () => {
    setShowCancelBox(true);
  };

  const handleCloseCancelBox = () => {
    setShowCancelBox(false);
    setCancellationReason('');
    setError('');
  };

  const handleReasonChange = (event) => {
    setCancellationReason(event.target.value);
  };

  const handleSubmitCancellation = async () => {
    try {

      if (!cancellationReason.trim()) {
        setError('Please provide a reason for cancellation.');
        return;
      }


     let res = await axios.post(`/api/payment/cancel/${data?.paymentid}`, { reason: cancellationReason,amount,orderid,products });
     if(res.status == 200){
      setRefresh(!refresh)
      setShowCancelBox(false);
      setCancellationReason('');
     }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='orderdetails-container'>
    <div className='orderdetails-box'>
        <h1 className='orderdetails-title'>ORDER DETAIL</h1>
        <div className='orderdetails-address'>
          <div className='orderdetails-desc'>

            <div className='orderdetails-desc-address'>
             <text>{data?.address.buildingname}</text>
             <text>{data?.address.locality}</text>
            <div className='orderdetails-desc-place'>
            <text>{data?.address.landmark} , </text>
            <text> {data?.address.pin}</text>
            </div>
            <div className='orderdetails-desc-place'>
            <text>{data?.address.sate}</text>,
            <text>{data?.address.district}</text> 
            </div>
            </div>

            <div className='orderdetails-total-price'>
              <text  className='orderdetails-total-price_title'>Total amount</text>
              <text>₹{data?.total+data?.shippingCharge}</text>
            </div>
            <div className='orderdetails-product_update'>
<text className='orderdetails-product_status'>{data?.orderstatus}</text>
{/* <text className='orderdetails-product_date'>July 26, 2011</text> */}
{data?.orderstatus  == 'Confirmed'? <text className='orderdetails-product_date'>{moment(data?.date.confirmed).format('LL')}</text>
:data?.orderstatus  == 'Shipped'? <text className='orderdetails-product_date'>{moment(data?.date.shipped).format('LL')}</text>
:data?.orderstatus  == 'Out For Delivery'? <text className='orderdetails-product_date'>{moment(data?.date.outdelivery).format('LL')}</text>
: <text className='orderdetails-product_date'>{moment(data?.date.deliverd).format('LL')}</text>}
</div>


<div className='orderdetails-product-mobileview'>
<div className='orderdetails-product_update-mobileview'>
<text className='orderdetails-product_status'>{data?.orderstatus}</text>
{/* <text className='orderdetails-product_date'>July 26, 2011</text> */}
{data?.orderstatus  == 'Confirmed'? <text className='orderdetails-product_date'>{moment(data?.date.confirmed).format('LL')}</text>
:data?.orderstatus  == 'Shipped'? <text className='orderdetails-product_date'>{moment(data?.date.shipped).format('LL')}</text>
:data?.orderstatus  == 'Out For Delivery'? <text className='orderdetails-product_date'>{moment(data?.date.outdelivery).format('LL')}</text>
: <text className='orderdetails-product_date'>{moment(data?.date.deliverd).format('LL')}</text>}
</div>
<div className='orderdetails-total-price-mobileview'>
              <text  className='orderdetails-total-price_title'>Total amount</text>
              <text>₹{data?.total}</text>
            </div>
</div>

    
          </div>
            
        </div>
        {data?.products.map((item,index)=>(
                  <div className='orderdetails-one_order'>
                  <img src={item.image} alt='productimg' onClick={()=>navigate(`/productdetail/${item.itemid}`)}/>
                  <text className='orderdetails-product_name'>{item.itemname}</text>
                  <text>QTY : {item.qty} </text>
                  <text className='orderdetails-product_price'>₹{item.price}</text>
                  </div>
        ))}
{data?.orderstatus && data?.orderstatus === 'Confirmed' && (
          <div className='orderdetails-cancel'>
            <button onClick={handleCancelClick}>Order Cancel</button>
          </div>
        )}
        {data?.orderstatus === "Cancelled" && data?.cancelReason &&( <div className='orderdetails-cancelReason'>
          <text>{data?.cancelReason}</text>
        </div>)}
        </div>

        {showCancelBox && (
          <div className='orderdetails-overlay'>
        <div className='orderdetails-cancel-box'>
          <textarea
            className='orderdetails-cancel-textarea'
            placeholder='Write your reason for cancellation...'
            value={cancellationReason}
            onChange={handleReasonChange}
          />
          {error && <p className="error-message">{error}</p>}
          <div className='orderdetails-cancel-buttons'>
            <button onClick={handleSubmitCancellation}>Submit</button>
            <span className='orderdetails-cancel-close' onClick={handleCloseCancelBox}>
              &times;
            </span>
          </div>
        </div>
        </div>
      )}

        </div>
  )
}

export default Orderdetail