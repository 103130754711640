import React from "react";
import Footer from "../footer/Footer";
import NavBar from "../navbar/NavBar";
import "./Aboutus.css";
import Logo from "../../assets/about-us-images/about-us-logo.jpeg";
const AboutUsPage = () => {
  return (
    <div>
      <NavBar />
      <div className="aobutus-main-wrapper">
        <div className="left-section-logo-about-us">
          <img src={Logo} alt="" />
        </div>
        <div className="main-contaner-about-us">
          <div>
          <h4>What is <b> inkista </b></h4>

            <p>
              In a world dominated by digital screens and constant distractions,
              finding moments of peace and self-discovery is essential. At
              Inkista, we understand the importance of these moments, which is
              why we've meticulously crafted a range of products designed to
              cater to individuals from all walks of life – from busy
              professionals to students and even children seeking refuge from
              the daily hustle.
            </p>
          </div>
          <div>
            <p>
              Inkista isn't just a book publishing house; it's a sanctuary. Each
              of our products is thoughtfully designed to guide you on a journey
              of creativity, self-awareness, and communication enhancement. From
              fostering critical thinking to promoting relaxation and happiness,
              our products serve as a haven where you can escape the digital
              noise and fully immerse yourself in the joy of being present in
              the moment.
            </p>
          </div>

          <div>
            <h4>Our Mission</h4>
            <p>
              Our mission at Inkista is to ignite creativity, foster
              self-expression, and promote mindfulness through our thoughtfully
              curated products. We believe in the transformative power of pen
              and paper – where ideas become reality, dreams evolve into plans,
              and moments are immortalized as memories.
            </p>
          </div>

          <div>
            <p>
              Join us on a journey of self-discovery and productivity, where
              every stroke of ink brings you closer to realizing your true
              potential
            </p>
          </div>
        </div>

      </div>
      <Footer />

    </div>
  );
};

export default AboutUsPage;
