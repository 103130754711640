import React from 'react'
import Addbannermngs from '../../component/admin/bannermng/Addbannermng';
import Navbar from '../../component/admin/navbar/Navbar';

const Addbannermng = () => {
  return (
    <div>
        <Navbar />
        <Addbannermngs />
    </div>
  )
}

export default Addbannermng