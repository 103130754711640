import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "../../../axios";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import "./AddressGuest.css";
import Cookies from "js-cookie";
import LoadingShimmer from "../../admin/loadingshimmer/LoadingShimmer";

const AddressGuest = () => {
  const userid = localStorage.getItem("userid");

  const [address, setAddress] = useState();
  const [cartdata, setCartdata] = useState([]);
  const [grandtotal, setGrandtotal] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [deliveryCharge, setDeliveryCharge] = useState();
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        let cartId = Cookies.get("cartId");
        if (userid) {
          let res = await axios.get(`/api/getcartitem/${userid}`, {
            withCredentials: true,
          });
          setCartdata(res.data.cartitem);
          setGrandtotal(res.data.grandtotal);
        } else if (cartId) {
          let res = await axios.get(`/api/getcartitem/guest/${cartId}`);
          setCartdata(res.data.cartitem);
          setGrandtotal(res.data.grandtotal);
        } else {
          setCartdata([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const deliveryChargeRes = await axios.get("/api/getalldeliverycharge");

        // Extract delivery charge data from the response
        const deliveryCharges = deliveryChargeRes.data;

        // Sort delivery charges by priceLesser value
        deliveryCharges.sort((a, b) => a.priceLesser - b.priceLesser);

        // Find the applicable delivery charge based on grand total
        let applicableCharge = null; // Default charge

        for (let i = 0; i < deliveryCharges.length; i++) {
          const charge = deliveryCharges[i];
          if (
            grandtotal >= charge.priceGreater &&
            grandtotal <= charge.priceLesser
          ) {
            applicableCharge = charge.charge;
            break;
          }
        }

        // Set the delivery charge to the applicable charge
        setDeliveryCharge((prevCharge) => {
          if (prevCharge !== applicableCharge) {
            return applicableCharge;
          }
          return prevCharge;
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [grandtotal]);

  const tot = grandtotal;

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const addressSchema = Yup.object().shape({
    name: Yup.string().required("Enter your name"),
    mobile: Yup.string()
      .min(10)
      .max(10)
      .matches(phoneRegExp, "Phone number is not valid"),
      email:Yup.string().email(),
    pin: Yup.string().min(6).max(6).required("Enter valid pincode"),
    locality: Yup.string().required("Enter your locality"),
    buildingname: Yup.string().required("Enter your Building/Flat name"),
    landmark: Yup.string().required("Enter your landmark"),
    district: Yup.string().required("Enter your district"),
    state: Yup.string().required("Enter your state"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email:"",
      pin: "",
      locality: "",
      buildingname: "",
      landmark: "",
      district: "",
      state: "",
    },
    enableReinitialize: true,
    validationSchema: addressSchema,
    onSubmit: async (values) => {
      const amount = tot + deliveryCharge;
      setAddress(values);
      if (address) {
        const res = await axios.post("/api/guest/payment/order", { amount,address });
        handlePaymentVerify(res.data);
      }
    },
  });

  // handlePaymentVerify Function
  const handlePaymentVerify = async (data) => {
    let cartId = Cookies.get("cartId");
    const total = tot;
    const options = {
      key: "rzp_live_OlwO4GMy0qbPqV",
      amount: data.amount,
      currency: data.currency,
      name: "Inkista",
      description: "Thanks for buying",
      order_id: data.id,
      handler: async (response) => {
        try {
          const res = await axios.post("/api/guest/payment/verify", {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            cartId,
            total,
            deliveryCharge,
            cartdata,
            address,
          });

          console.log("new payment")
          console.log("res", res);
          console.log("new payment")
          setIsLoading(true);

          if (res.data.message === "Payment Successfully") {
            setIsLoading(false);
            Cookies.remove("cartId");
            navigate(`/shipping/checkout/sucsess/${res.data.updatedorder._id}`);
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#71447a",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div>
      <form  auto>
        <p className="addressGuest-left-container-title">Add address</p>
        <div className="addressGuest-left-container-field">
          <text>Name*</text>
          <input
            className="addressGuest-left-container-field-text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.errors.name && (
            <p className="addressGuest-error-message">{formik.errors.name}</p>
          )}
        </div>
        <div className="addressGuest-left-container-field">
          <text>Mobile*</text>
          <input
            className="addressGuest-left-container-field-text"
            name="mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
          />
          {formik.errors.mobile && (
            <p className="addressGuest-error-message">{formik.errors.mobile}</p>
          )}
        </div>

        <div className="addressGuest-left-container-field">
          <text>Email*</text>
          <input
            className="addressGuest-left-container-field-text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {formik.errors.email && (
            <p className="addressGuest-error-message">{formik.errors.email}</p>
          )}
        </div>

        <div className="addressGuest-left-container-field">
          <text>Pin Code*</text>
          <input
            name="pin"
            className="addressGuest-left-container-field-text"
            value={formik.values.pin}
            onChange={formik.handleChange}
          />
          {formik.errors.pin && (
            <p className="addressGuest-error-message">{formik.errors.pin}</p>
          )}
        </div>
        <div className="addressGuest-left-container-field">
          <text>Locality/Area/Street*</text>
          <input
            name="locality"
            className="addressGuest-left-container-field-text"
            value={formik.values.locality}
            onChange={formik.handleChange}
          />
          {formik.errors.locality && (
            <p className="addressGuest-error-message">
              {formik.errors.locality}
            </p>
          )}
        </div>
        <div className="addressGuest-left-container-field">
          <text>Flat Number / Building Name*</text>
          <input
            name="buildingname"
            className="addressGuest-left-container-field-text"
            value={formik.values.buildingname}
            onChange={formik.handleChange}
          />
          {formik.errors.buildingname && (
            <p className="addressGuest-error-message">
              {formik.errors.buildingname}
            </p>
          )}
        </div>
        <div className="addressGuest-left-container-field">
          <text>Landmark*</text>
          <input
            name="landmark"
            className="addressGuest-left-container-field-text"
            value={formik.values.landmark}
            onChange={formik.handleChange}
          />
          {formik.errors.landmark && (
            <p className="addressGuest-error-message">
              {formik.errors.landmark}
            </p>
          )}
        </div>
        <div className="addressGuest-left-container-field">
          <text>District/City*</text>
          <input
            name="district"
            className="addressGuest-left-container-field-text"
            value={formik.values.district}
            onChange={formik.handleChange}
          />
          {formik.errors.district && (
            <p className="addressGuest-error-message">
              {formik.errors.district}
            </p>
          )}
        </div>
        <div className="addressGuest-left-container-field">
          <text>State*</text>
          <input
            name="state"
            className="addressGuest-left-container-field-text"
            value={formik.values.state}
            onChange={formik.handleChange}
          />
          {formik.errors.state && (
            <p className="addressGuest-error-message">{formik.errors.state}</p>
          )}
        </div>
        <div className="addressGuest-left-container-savebutton">
          <button onClick={formik.handleSubmit} type="submit">PROCEED TO PAYMENT</button>
        </div>
      </form>
      <div className="loading-shimmer-add-product">
        {isLoading && (
          <div className="loading-shimmer">
            <LoadingShimmer />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressGuest;
