import React, { useState } from 'react';
import "./DeliveryCharge.css";
import LoadingShimmer from '../loadingshimmer/LoadingShimmer';

const AddDeliveryCharge = ({addCharge}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [priceGreater,setPriceGrater] = useState();
  const [priceLesser,setPriceLesser] = useState();
  const [charge,setCharge] = useState();
  const [errorMessage, setErrorMessage] = useState('');


  const validateInputs = () => {
    const regex = /^\d*\.?\d*$/; 
    if (priceGreater === '' || priceLesser === '' || charge === '') {
      setErrorMessage('All fields are required.');
      return false;
    } else if (!regex.test(priceGreater) || !regex.test(priceLesser) || !regex.test(charge)) {
      setErrorMessage('Please enter valid numeric values for all fields.');
      return false;
    } else if (parseFloat(priceLesser) <= parseFloat(priceGreater)) {
      setErrorMessage('Price Less field must be greater than Price Greater field.');
      return false;
    }
    return true;
  };

  const handlePriceLesserChange = (e) => {
    setPriceLesser(e.target.value);
    setErrorMessage('');
  };



  const AddchargeHandler = async () => {
    setIsLoading(true)

    if (!validateInputs()) {
      setIsLoading(false);
      return; // Stop execution if inputs are not valid
    }

    try{
      const newCharge = {
        priceGreater,
        priceLesser,
        charge
      };
        let res = await addCharge(newCharge)
        if(res.status == 400){
          setErrorMessage(res.data.message)
        }else{
          setPriceGrater('')
          setPriceLesser('')
          setCharge('')
          setErrorMessage('');
        }
    }catch(error){
      console.log(error)
    } finally {
      setIsLoading(false);
     }
  }

  return (
    <>

    <div className='AddDeliveryCharge-container' >
       
    <div className='AddDeliveryCharge-box'>
    
        <div className='AddDeliveryCharge-box-title'>
            <h1>ADD CHARGE</h1>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
<div className='AddDeliveryCharge-box-dataContainer'>
        <div className='AddDeliveryCharge-box-data'>
            <text>Price Lesser</text>
            <input value={priceGreater} onChange={(e)=> setPriceGrater(e.target.value)}/>
        </div>
        <div className='AddDeliveryCharge-box-data'>
            <text>Price Greater</text>
            <input value={priceLesser} onChange={handlePriceLesserChange}/>
        </div>
        <div className='AddDeliveryCharge-box-data'>
            <text> Delivery Charge</text>
            <input value={charge} onChange={(e)=> setCharge(e.target.value)}/>
        </div>
        </div>
        <div className='AddDeliveryCharge-box-button' onClick={AddchargeHandler}>
            <button>ADD</button>
        </div>
        
    </div>

 

</div>
   <div className="loading-shimmer-add-product">
   {isLoading && <div className="loading-shimmer">
     <LoadingShimmer/>
     </div>}
   </div>
   </>
  )
}

export default AddDeliveryCharge
