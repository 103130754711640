import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./cart.css";
import axios from "../../axios";
import EmptyCart from "./EmptyCart";
import { Alert } from "@mui/material";
import Cookies from "js-cookie";
// import { trackMetaPixelEvent } from "../../tracking/FacebookPixelEvent";

const Cart = () => {
  const [cartdata, setCartdata] = useState([]);
  const [refresh, setRefresh] = useState(false);  
  const [sucess, setSuccess] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState("");

  const navigate = useNavigate();

  const userid = localStorage.getItem("userid");


  useEffect(() => {
    (async () => {
      try {
        let cartId = Cookies.get("cartId");
        if (userid) {
          console.log("userid");
          let res = await axios.get(`/api/getcartitem/${userid}`, {
            withCredentials: true,
          });
          setCartdata(res.data);
          // setGrandtotal(res.data.grandtotal);
        } else if (cartId) {
          console.log("cartid");
          let res = await axios.get(`/api/getcartitem/guest/${cartId}`);
          setCartdata(res.data);
          // setGrandtotal(res.data.grandtotal);
        } else {
          setCartdata([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refresh]);


  console.log("cartdata ==", cartdata);
  const removeitem = async (itemid) => {
    try {
      if (userid) {
        let res = await axios.post(
          `/api/removecartitem/${userid}`,
          { itemid },
          { withCredentials: true }
        );
        console.log(res);
        setSuccess(true);
        setRefresh(!refresh);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      } else {
        let cartId = Cookies.get("cartId");
        let res = await axios.post(`/api/removecartitem/guest/${cartId}`, {
          itemid,
        });
        console.log(res);
        setSuccess(true);
        setRefresh(!refresh);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addqty = async (itemid) => {
    try {
      if (userid) {
        let res = await axios.post(
          `/api/updatecartqty/${userid}`,
          { itemid },
          { withCredentials: true }
        );
        setRefresh(!refresh);
        console.log(res);
      } else {
        let cartId = Cookies.get("cartId");
        let res = await axios.post(`/api/updatecartqty/guest/${cartId}`, {
          itemid,
        });
        setRefresh(!refresh);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const lessqty = async (itemid) => {
    try {
      if (userid) {
        let res = await axios.post(
          `/api/lesscartqty/${userid}`,
          { itemid },
          { withCredentials: true }
        );
        setRefresh(!refresh);
        console.log(res);
      } else {
        let cartId = Cookies.get("cartId");
        let res = await axios.post(`/api/lesscartqty/guest/${cartId}`, {
          itemid,
        });
        setRefresh(!refresh);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function gotoshipping() {
    // trackMetaPixelEvent('initiateCheckout', { button: 'proceed to shipping' });

    navigate("/shipping/checkout");
  }

  useEffect(() => {
    (async () => {
      try {
        const deliveryChargeRes = await axios.get('/api/getalldeliverycharge');

        // Extract delivery charge data from the response
        const deliveryCharges = deliveryChargeRes.data;

        // Sort delivery charges by priceLesser value
        deliveryCharges.sort((a, b) => a.priceLesser - b.priceLesser);

        // Find the applicable delivery charge based on grand total
        let applicableCharge = null; // Default charge

        for (let i = 0; i < deliveryCharges.length; i++) {
          const charge = deliveryCharges[i];
          if (
            cartdata?.grandtotal >= charge.priceGreater &&
            cartdata?.grandtotal <= charge.priceLesser
          ) {
            applicableCharge = charge.charge;
            break;
          }
        }

        // Set the delivery charge to the applicable charge
        setDeliveryCharge((prevCharge) => {
          if (prevCharge !== applicableCharge) {
            return applicableCharge;
          }
          return prevCharge;
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [cartdata?.grandtotal]);

  return (
    <div className="cart-container">
      {/* <div className='cart-title'>
            <h1>SHOPPING CART</h1>
        </div>   */}
      {sucess ? (
        <Alert className="success-alert" severity="success">
          Cart item has removed{" "}
        </Alert>
      ) : (
        <></>
      )}
      {cartdata?.cartitem && cartdata?.grandtotal !== 0 ? (
        <div className="cart-box">
          <div className="cart-box-item-list">
            {cartdata &&
              cartdata.cartitem &&
              cartdata.cartitem?.map((item) => (
                <div className="cart-box-item" key={item.itemname}>
                  <div
                    className="cart-box-item-img"
                    onClick={() => navigate(`/productdetail/${item.itemid}`)}
                  >
                    <img src={item?.image} alt="img" />
                  </div>
                  <div className="cart-box-item-des">
                    <div className="cart-box-item-des-title">
                      <p>{item.itemname}</p>
                    </div>

                    <p className="cart-box-item-des-price">₹{item?.price}</p>
                    <p>Quantity</p>
                    <div className="cart-box-item-des-qty">
                      <button
                        className="cart-box-item-des-qty-ri"
                        value={item?.itemid}
                        onClick={(e) => lessqty(e.target.value)}
                      >
                        -
                      </button>
                      <h6 className="product-quantity-count-increment-and-decrement">
                        {item?.qty}
                      </h6>
                      <button
                        className="cart-box-item-des-qty-ai"
                        value={item?.itemid}
                        onClick={(e) => addqty(e.target.value)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="cart-box-item-button">
                    <button
                      value={item?.itemid}
                      onClick={(e) => removeitem(e.target.value)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <div className="cart-box-total">
            <p className="cart-box-total-title">Order Details</p>
            <div className="cart-box-total-detail">
              <p>Price</p>
              <p>₹{cartdata.grandtotal}</p>
            </div>
            <div className="cart-box-total-detail">
              <p>Items</p>
              <p>{cartdata.cartitem?.length}</p>
            </div>
            <div className="cart-box-total-detail">
              <p>Delivery Charges</p>
             {deliveryCharge ? <p>₹{deliveryCharge}</p> : <p>Free</p>}
            </div>

            <div className="cart-box-total-detail-amount">
              <p>Total Amount</p>
              <p>₹{cartdata?.grandtotal + deliveryCharge}</p>
            </div>

            <div className="cart-box-total-detail-btn">
              <button onClick={gotoshipping}>PROCEED TO SHIPPING</button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <EmptyCart />
        </div>
      )}
    </div>
  );
};

export default Cart;
