import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./productmng.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import axios from "../../../axios";
import LoadingShimmer from "../loadingshimmer/LoadingShimmer";
import imageCompression from "browser-image-compression"; 

const Addproduct = () => {
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loadingVideos, setLoadingVideos] = useState([]);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

    // Function to handle image upload and compression
    const handleImageUpload = async (e,index) => {
      const file = e.target.files[0];
      
      // Configure compression options
      const options = {
        maxSizeMB: 0.1, // Max size in megabytes
        maxWidthOrHeight: 1920, // Max width or height
        useWebWorker: true, // Use web worker for faster compression
      };
  
      try {
        const compressedFile = await imageCompression(file, options);
        const base64 = await convertBase64(compressedFile);
        setImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = base64;
          return updatedImages;
        });
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    };


  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get("/api/admin/getcatagory", {withCredentials:true});
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);


  // const uploadImage = async (e, index) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertBase64(file);
  //   setImages((prevImages) => {
  //     const updatedImages = [...prevImages];
  //     updatedImages[index] = base64;
  //     return updatedImages;
  //   });
  // };

  
  const uploadVideo = async (e, index) => {
    const file = e.target.files[0];
    if (file.size > 100 * 1024 * 1024) {
      alert("Video file size exceeds the limit of 100MB");
      return;
    }
    if (!file.type.startsWith("video/")) {
      alert("Please upload a valid video file");
      return;
    }

    const formData = new FormData();
    formData.append("video", file);

    setLoadingVideos((prevLoadingVideos) => {
      const updatedLoadingVideos = [...prevLoadingVideos];
      updatedLoadingVideos[index] = true;
      return updatedLoadingVideos;
    });

    try {
      const res = await axios.post("/api/admin/uploadvideo", formData);
      setVideos((prevVideos) => {
        const updatedVideos = [...prevVideos];
        updatedVideos[index] = res.data.url;
        return updatedVideos;
      });
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Video upload failed");
    }finally {
      setLoadingVideos((prevLoadingVideos) => {
        const updatedLoadingVideos = [...prevLoadingVideos];
        updatedLoadingVideos[index] = false;
        return updatedLoadingVideos;
      });
    }
  };

  


  const addImageField = () => {
    setImages((prevImages) => [...prevImages, ""]);
  };

  const removeImageField = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };


  const addVideoField = () => {
    setVideos((prevVideos) => [...prevVideos, ""]);
    setLoadingVideos((prevLoadingVideos) => [...prevLoadingVideos, false]);
  };

  const removeVideoField = (index) => {
    setVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos.splice(index, 1);
      return updatedVideos;
    });

    setLoadingVideos((prevLoadingVideos) => {
      const updatedLoadingVideos = [...prevLoadingVideos];
      updatedLoadingVideos.splice(index, 1);
      return updatedLoadingVideos;
    });

  };


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Enter the product name"),
      detail: Yup.string().required("Enter the product detail"),
      material: Yup.string(),
      color: Yup.string(),
      catagory: Yup.string().required("Select a catagory"),
      pointone: Yup.string(),
      pointtwo: Yup.string(),
      pointthree: Yup.string(),
      pointfour: Yup.string(),
      stock: Yup.string().required("Enter the stock"),
      wholesaleprice: Yup.string("Enter the price"),
      retailprice: Yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onsubmit = async (data) => {
    try {
      setIsLoading(true);
      const newproduct = {
        name: data.name,
        detail: data.detail,
        material: data.material,
        color: data.color,
        catagory: data.catagory,
        pointone: data.pointone,
        pointtwo: data.pointtwo,
        pointthree: data.pointthree,
        pointfour: data.pointfour,
        stock: data.stock,
        wholesaleprice: data.wholesaleprice,
        retailprice: data.retailprice,
        images,
        videos
      };
      console.log(newproduct)
      const res = await axios.post("/api/admin/addproduct", newproduct,{withCredentials:true});
      if (res.data) {
        navigate("/admin/showproduct");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <>
      <div className="addproduct-container">
        <div className="addproduct-box">
          <div className="addproduct-box-title">
            <div
              onClick={() => navigate("/admin/showproduct")}
              className="addproduct-viewbutton"
            >
              <button>View Product</button>
            </div>
            <h1>Add Product</h1>
          </div>
          <form onSubmit={handleSubmit(onsubmit)}>
            <div className="addproduct-box-data">
              <text>Product Name</text>
              <input {...register("name")} />
              {console.log("error = " + errors.name)}
              {errors.name && (
                <p className="register-error-message">{errors.name.message}</p>
              )}
            </div>
            <div className="addproduct-box-data">
              <text>Product Detail</text>
              <textarea {...register("detail")}></textarea>

              {errors.detail && (
                <p className="register-error-message">
                  {errors.detail.message}
                </p>
              )}
            </div>
            <div className="addproduct-box-data">
              <text>Features</text>
              <textarea {...register("material")} />
            </div>
            <div className="addproduct-box-data">
              <text>Specs</text>
              <textarea {...register("color")} />
            </div>
            <div className="addproduct-box-data">
              <text>Catagory</text>
              <select
                className="addproduct-box-data_dropdown"
                {...register("catagory")}
              >
                {data.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </select>
              {errors.catagory && (
                <p className="register-error-message">
                  {errors.catagory.message}
                </p>
              )}
            </div>
            <div className="addproduct-box-data">
              <text>Author Name</text>
              <input {...register("pointone")} />
              {errors.pointone && (
                <p className="register-error-message">
                  {errors.pointone.message}
                </p>
              )}
            </div>

            <div className="addproduct-box-data">
              <text>Short Descreption</text>
              <textarea {...register("pointtwo")} />
            </div>
            <div className="addproduct-box-data">
              <text>Full Descreption</text>
              <textarea {...register("pointthree")} />
            </div>

            <div className="addproduct-box-data">
              <text>Benefits</text>
              <textarea {...register("pointfour")} />
            </div>
            <div className="addproduct-box-data">
              <text>Stock</text>
              <input {...register("stock")} type="number" />
              {errors.stock && (
                <p className="register-error-message">{errors.stock.message}</p>
              )}
            </div>


<div  className="addproduct-box-data">
{images.map((image, index) => (
            <div key={index} className="addproduct-box-data">
              <text>Image {index + 1}</text>
              {image && <img src={image} className="addproduct-img" />}
              <input
                type="file"
                onChange={(e) => handleImageUpload(e, index)}
              />
              <button onClick={() => removeImageField(index)}>
                Remove
              </button>
            </div>
          ))}
          <button onClick={addImageField}>Add Image</button>
</div>

<div className="addproduct-box-data">
              {videos.map((video, index) => (
                <div key={index} className="addproduct-box-data">
                  <text>Video {index + 1}</text>
                  {video && <video src={video} className="addproduct-video" controls />}
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => uploadVideo(e, index)}
                  />
                  {loadingVideos[index] && (
                    <div className="loading-video">
                      <LoadingShimmer />
                    </div>
                  )}
                  <button type="button" onClick={() => removeVideoField(index)}>
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" onClick={addVideoField}>Add Video</button>
            </div>


            <div className="addproduct-box-price">
              <div className="addproduct-box-data">
                <text>M.R.P</text>
                <input {...register("wholesaleprice")} type="number"/>
                {errors.wholesaleprice && (
                <p className="register-error-message">{errors.wholesaleprice.message}</p>
              )}
              </div>
              <div className="addproduct-box-data">
                <text>Selling Price</text>
                <input {...register("retailprice")}  type="number" />
              </div>
            </div>

            <div className="addproduct-box-button">
              <button type="submit">Add Item</button>
            </div>
          </form>
        </div>
      </div>
      <div className="loading-shimmer-add-product">
        {isLoading && (
          <div className="loading-shimmer">
            <LoadingShimmer />
          </div>
        )}
      </div>
    </>
  );
};

export default Addproduct;


