import React from 'react'
import Ordermngs from '../../component/admin/ordermng/Ordermng'
import Navbar from '../../component/admin/navbar/Navbar'

const Ordermng = () => {
  return (
    <div>
        <Navbar />
        <Ordermngs />
    </div>
  )
}

export default Ordermng