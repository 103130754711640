import React from 'react'
import NavBar from '../component/navbar/NavBar'
import Otps from '../component/Otp/Otp'

const Otp = () => {
  return (
    <div>
        <NavBar/>
        <Otps />
    </div>
  )
}

export default Otp;