import React, { useRef } from "react";
import "./Otp.css";

const Otp = ({ otp, setOtp }) => {
  const refs = useRef([]);

  const handleChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOtp(newOtp);
    
    // Move focus to the next input if available
    if (event.target.value !== "" && index < 5) {
      refs.current[index + 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("Text");
    const pasteArray = pasteData.split("");

    const newOtp = [...otp];
    pasteArray.forEach((digit, index) => {
      if (index < 6) {
        newOtp[index] = digit;
        if (index < 5) {
          refs.current[index + 1].focus();
        }
      }
    });
    setOtp(newOtp);
  };

  return (
    <div className="otp-container">
      <p className="otp-label">Enter OTP:</p>
      <div className="otp-inputs">
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (refs.current[index] = el)}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e)}
            onPaste={handlePaste}
            className="otp-input"
          />
        ))}
      </div>
    </div>
  );
};

export default Otp;
