import axios from "../../axios";
import React, { useEffect, useRef, useState } from "react";
import "./Homeproduct.css";
import { useNavigate } from "react-router-dom";

const Homeproducts = () => {
  const [data, setData] = useState([]);
  const [cartdata, setCartdata] = useState();
  const navigate = useNavigate();
  const messagesEndRef = useRef();

  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const [addCardLoading, setAddCartLoading] = useState(false);
  const [proid, setProid] = useState("");
  const [loading, setLoading] = useState(true);

  const user = localStorage.getItem("userid");

  useEffect(() => {
    (async () => {
      let res = await axios.get("/api/getsortedproduct");
      setData(res.data);
      setLoading(false);
    })();
  }, []);
  console.log("data====", data);

  useEffect(() => {
    (async () => {
      try {
        if (user) {
          let res = await axios.get(`/api/getcartitem/${user}`, {
            withCredentials: true,
          });

          setCartdata(res.data.cartitem);
          setLoading(false);
        } else {
          setCartdata();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [refresh]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [error]);

  function getproductdetail(id) {
    navigate(`/productdetail/${id}`);
  }

  const addtocart = async (productid) => {
    const qty = 1;

    try {
      if (user) {
        setProid(productid);
        setAddCartLoading(true);
        let res = await axios.post(`/api/addtocart/${user}/${qty}`, {
          productid,
        });
        setAddCartLoading(false);
        setProid("");
        setRefresh(!refresh);
      } else {
        alert();
      }
    } catch (error) {
      console.log(error);
    }
  };

  function alert() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  }

  function gotocart() {
    navigate("/cart");
  }

  const Skeleton = () => (
    <div className="homeproducts-box ">
      <div className="homeproducts-img-container"></div>
      <div className="homeproducts-text-box">
        <div className="homeproducts-text-box-title"></div>
      </div>
    </div>
  );

  return (
    <>
      {error && (
        <div ref={messagesEndRef} className="products-box-error">
          <p>Please make login</p>
        </div>
      )}
      {loading ? (
        <Skeleton /> // Render skeleton if loading
      ) : (
        <div className="homeproducts-box-container">
          {/* {data?.slice(0, 6).map((item) => ( */}
          {data?.map((item) => (
            <div className="homeproducts-box" key={item._id}>
              <div className="homeproducts-img-container">
                <img
                  className="homeproducts-img"
                  src={item.images?.[0]} // Use the first image from the images array
                  onClick={() => getproductdetail(item._id)}
                  alt={item.name}
                />
              </div>
              <div className="homeproducts-text-box">
                <div className="homeproducts-text-box-title">
                  <p className="product-name-home-products">{item.name}</p>
                  <p>{item.pointone}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Homeproducts;