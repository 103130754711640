import React, { useEffect, useState, useRef } from "react";
import "./Productlisting.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import ComingSoon from "../../assets/coming-soon/coming-soon.png";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
//import { addToCartPixel } from "../../tracking/FacebookPixelEvent";
// import { trackMetaPixelEvent } from '../../tracking/FacebookPixelEvent';

const Listproducts = () => {
  const [products, setProducts] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const messagesEndRef = useRef();

  const [refresh, setRefresh] = useState(false);
  const [cartdata, setCartdata] = useState();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  // const location = useLocation();
  // const lastSegment = location.pathname.split("/").pop();
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split("/");
  const productNameEncoded = parts[parts.length - 1];

  // Decode the product name
  const productName = decodeURIComponent(productNameEncoded);
  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    // trackMetaPixelEvent('Category Page', { button: 'Category Page' });
 
    (async () => {
      try {
        let res = await axios.get(`/api/getcatagoryproducts/${params.item}`);
        setProducts(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const user = localStorage.getItem("userid");

  function getproductdetail(id) {
    navigate(`/productdetail/${id}`);
  }

  const addtocart = async (productid, type) => {
    const qty = 1;
    try {
      let cartId = Cookies.get("cartId");

      if (!cartId) {
        cartId = uuidv4(); // generate a new unique ID
        Cookies.set("cartId", cartId, {
          expires: 7,
          secure: true,
          sameSite: "strict",
        }); // set cookie for 7 days
      }

      if (user) {
        let res = await axios.post(
          `/api/addtocart/${user}/${qty}`,
          {
            productid,
          },
          { withCredentials: true }
        );
        if (type === "cart") {
          setRefresh(!refresh);
        } else {
          navigate("/cart");
        }
      } else {
        let res = await axios.post(`/api/addtocart/guest/${cartId}/${qty}`, {
          productid,
        });
        if (type === "cart") {
          setRefresh(!refresh);
        //  addToCartPixel();
        } else {
          navigate("/cart");
          setRefresh(!refresh);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        let cartId = Cookies.get("cartId");
        if (user) {
          let res = await axios.get(`/api/getcartitem/${user}`, {
            withCredentials: true,
          });
          setCartdata(res.data?.cartitem);
        } else if (cartId) {
          let res = await axios.get(`/api/getcartitem/guest/${cartId}`);
          console.log(res);
          setCartdata(res.data?.cartitem);
        } else {
          setCartdata([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refresh]);

  function gotocart() {
    navigate("/cart");
  }
  // const AvailableOrOutOfStockStatus = products.stock;

  console.log("cartdata list procuct=", products);

  return (
    <>
      {products.length > 0 ? (
        <div className="products-box-container">
          {products?.map((item) => (
            <div className="products-box">
              <div className="products-img-container">
                <img
                  className="products-img"
                  src={item.images[0]}
                  alt="img"
                  onClick={(e) => getproductdetail(item._id)}
                />
              </div>
              <div className="products-text-box">
                <div
                  className="products-text-box-title"
                  onClick={(e) => getproductdetail(item._id)}
                >
                  <p>{item.name}</p>
                </div>
                <div
                  className="products-text-box-title"
                  onClick={(e) => getproductdetail(item._id)}
                >
                  <p className="author-name">{item.pointone}</p>
                </div>
                <div
                  className="products-text-box-price"
                  onClick={(e) => getproductdetail(item._id)}
                >
                  <h3 className="products-text-box-retialprice">
                    ₹ {item.retailprice}
                  </h3>
                  {/* <h3 className='products-text-box-discountprice'>{item.discountprice}</h3> */}
                </div>
                <div>
                  <p className="out-of-stock-product-listing">
                    {item.stock <= 0 && <span>Out of Stock</span>}
                  </p>
                </div>

                <div className="products-text-box-btn">
                  {item.stock <= 0 ? ( // Check if stock is less than or equal to zero
                    <div>
                      <button
                        className="products-text-box-gotocrtbtn disabled"
                        disabled
                      >
                        Go To Cart
                      </button>
                      <button
                        className="products-text-box-crtbtn disabled"
                        disabled
                      >
                        Add To Cart
                      </button>
                    </div>
                  ) : (
                    <>
                      {cartdata &&
                      cartdata.some(
                        (cartitem) => cartitem?.itemid === item?._id
                      ) ? (
                        <button
                          className="products-text-box-gotocrtbtn"
                          value={item._id}
                          onClick={gotocart}
                        >
                          Go To Cart
                        </button>
                      ) : (
                        <button
                          className="products-text-box-crtbtn"
                          value={item._id}
                          onClick={(e) => addtocart(e.target.value, "cart")}
                        >
                          Add To Cart
                        </button>
                      )}

                      <button
                        className="products-text-box-buybtn"
                        value={item._id}
                        onClick={(e) => getproductdetail(e.target.value)}
                      >
                        Buy Now
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="books-soon-wrapper">
          <h4>Books will soon be available in the {productName} category</h4>
          <img src={ComingSoon} alt="Coming soon" />
        </div>
      )}
    </>
  );
};

export default Listproducts;
