import React, { useEffect, useState } from "react";
import "./productmng.css";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingShimmer from "../loadingshimmer/LoadingShimmer";
import imageCompression from "browser-image-compression"; 

const Editproduct = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loadingVideos, setLoadingVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const uploadImage = async (e, index) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertBase64(file);
  //   setImages((prevImages) => {
  //     const updatedImages = [...prevImages];
  //     updatedImages[index] = base64;
  //     return updatedImages;
  //   });
  // };

  const handleImageUpload = async (e,index) => {
    const file = e.target.files[0];
    
    // Configure compression options
    const options = {
      maxSizeMB: 0.1, // Max size in megabytes
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true, // Use web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const base64 = await convertBase64(compressedFile);
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[index] = base64;
        return updatedImages;
      });
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  const addImageField = () => {
    setImages((prevImages) => [...prevImages, ""]);
  };

  const removeImageField = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const uploadVideo = async (e, index) => {
    const file = e.target.files[0];
    if (file.size > 100 * 1024 * 1024) {
      alert("Video file size exceeds the limit of 100MB");
      return;
    }
    if (!file.type.startsWith("video/")) {
      alert("Please upload a valid video file");
      return;
    }

    const formData = new FormData();
    formData.append("video", file);

    setLoadingVideos((prevLoadingVideos) => {
      const updatedLoadingVideos = [...prevLoadingVideos];
      updatedLoadingVideos[index] = true;
      return updatedLoadingVideos;
    });


    try {
      const res = await axios.post("/api/admin/uploadvideo", formData);
      setVideos((prevVideos) => {
        const updatedVideos = [...prevVideos];
        updatedVideos[index] = res.data.url;
        return updatedVideos;
      });
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Video upload failed");
    }finally {
      setLoadingVideos((prevLoadingVideos) => {
        const updatedLoadingVideos = [...prevLoadingVideos];
        updatedLoadingVideos[index] = false;
        return updatedLoadingVideos;
      });
    }
  };


  const addVideoField = () => {
    setVideos((prevVideos) => [...prevVideos, ""]);
    setLoadingVideos((prevLoadingVideos) => [...prevLoadingVideos, false]);
  };

  const removeVideoField = (index) => {
    setVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos.splice(index, 1);
      return updatedVideos;
    });

    setLoadingVideos((prevLoadingVideos) => {
      const updatedLoadingVideos = [...prevLoadingVideos];
      updatedLoadingVideos.splice(index, 1);
      return updatedLoadingVideos;
    });

  };

  useEffect(() => {
    (async () => {
      try {
        let data = await axios.get(`/api/admin/productdata/${params.id}`,{withCredentials:true});
        setData(data.data);
        setImages(data.data.images || []);
        setVideos(data.data.videos || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const validationSchemas = Yup.object({
    name: Yup.string().required("Enter the product name"),
    detail: Yup.string().required("Enter the product detail"),
    material: Yup.string(),
    color: Yup.string(),
    catagory: Yup.string().required("Select a catagory"),
    pointone: Yup.string(),
    pointtwo: Yup.string(),
    pointthree: Yup.string(),
    pointfour: Yup.string(),
    stock: Yup.string().required("Enter the stock"),
    wholesaleprice: Yup.string(),
    retailprice: Yup.string().required("Enter the selling price"),
  }).required();

  const { handleSubmit, errors, getFieldProps } = useFormik({
    initialValues: {
      name: data.name || "",
      detail: data.detail || "",
      material: data.material || "",
      color: data.color || "",
      catagory: data.catagory || "",
      pointone: data.pointone || "",
      pointtwo: data.pointtwo || "",
      pointthree: data.pointthree || "",
      pointfour: data.pointfour || "",
      stock: data.stock || "",
      wholesaleprice: data.wholesaleprice || "",
      retailprice: data.retailprice || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchemas,

    onSubmit: (values) => {
      (async () => {
        try {
          setIsLoading(true);
          const updateProduct = {
            ...values,
            images,
            videos
          };
          const res = await axios.post(
            `/api/admin/updateproduct/${params.id}`,
            updateProduct,{withCredentials:true}
          );
          console.log(res.data);
          if (res.data) {
            navigate("/admin/showproduct");
          }
        } catch (error) {
          console.log(error);
        }finally {
          setIsLoading(false);
        }
      })();
    },
  });

  return (
    <div className="addproduct-container">
      <div className="addproduct-box">
        <div className="addproduct-box-title">
          <div
            onClick={() => navigate("/admin/showproduct")}
            className="addproduct-viewbutton"
          >
            <button>View Product</button>
          </div>
          <h1>Add Product</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="addproduct-box-data">
            <text>Product Name</text>
            <input type="text" {...getFieldProps("name")} autoComplete="off" />
            {errors.name && (
              <p className="register-error-message">{errors.name}</p>
            )}
          </div>
          <div className="addproduct-box-data">
            <text>Product Detail</text>
            <textarea
              type="text"
              {...getFieldProps("detail")}
              autoComplete="off"
            />
            {errors.detail && (
              <p className="register-error-message">{errors.detail}</p>
            )}
          </div>
          <div className="addproduct-box-data">
            <text>Features</text>
            <textarea
              type="text"
              {...getFieldProps("material")}
              autoComplete="off"
            />
          </div>
          <div className="addproduct-box-data">
            <text>Specs</text>
            <textarea
              type="text"
              {...getFieldProps("color")}
              autoComplete="off"
            />
          </div>
          <div className="addproduct-box-data">
            <text>Author Name</text>
            <input
              type="text"
              {...getFieldProps("pointone")}
              autoComplete="off"
            />
          </div>

          <div className="addproduct-box-data">
            <text>Short Descreption</text>
            <textarea
              type="text"
              {...getFieldProps("pointtwo")}
              autoComplete="off"
            />
          </div>
          <div className="addproduct-box-data">
            <text>Full Descreption</text>
            <textarea
              type="text"
              {...getFieldProps("pointthree")}
              autoComplete="off"
            />
          </div>
          <div className="addproduct-box-data">
            <text>Benefits</text>
            <textarea
              type="text"
              {...getFieldProps("pointfour")}
              autoComplete="off"
            />
          </div>

          <div className="addproduct-box-data">
            <text>Stock</text>
            <input
              type="number"
              {...getFieldProps("stock")}
              autoComplete="off"
            />
            {errors.stock && (
              <p className="register-error-message">{errors.stock}</p>
            )}
          </div>

          <div className="addproduct-box-data">
            {images.map((image, index) => (
              <div key={index} className="addproduct-box-data">
                <text>Image {index + 1}</text>
                {image && <img src={image} className="addproduct-img" />}
                <input
                  type="file"
                  onChange={(e) => handleImageUpload(e, index)}
                />
                <button type="button" onClick={() => removeImageField(index)}>
                  Remove
                </button>
              </div>
            ))}
            <button type="button" onClick={addImageField}>
              Add Image
            </button>
          </div>

          <div className="addproduct-box-data">
            {videos.map((video, index) => (
              <div key={index} className="addproduct-box-data">
                <text>Video {index + 1}</text>
                {video && <video src={video} className="addproduct-video" controls />}
                <input
                  type="file"
                  onChange={(e) => uploadVideo(e, index)}
                />
                 {loadingVideos[index] && (
                    <div className="loading-video">
                      <LoadingShimmer />
                    </div>
                  )}
                <button type="button" onClick={() => removeVideoField(index)}>
                  Remove
                </button>
              </div>
            ))}
            <button type="button" onClick={addVideoField}>
              Add Video
            </button>
          </div>

          <div className="addproduct-box-price">
            <div className="addproduct-box-data">
              <text>M.R.P</text>

              <input
                type="number"
                {...getFieldProps("wholesaleprice")}
                autoComplete="off"
              />
            </div>
            <div className="addproduct-box-data">
              <text>Selling Price</text>
              <input
                type="number"
                {...getFieldProps("retailprice")}
                autoComplete="off"
              />
              {errors.retailprice && (
                <p className="register-error-message">{errors.retailprice}</p>
              )}
            </div>
          </div>

          <div className="addproduct-box-button">
          <button type="submit" disabled={isLoading}>
          {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
      <div className="loading-shimmer-add-product">
        {isLoading && (
          <div className="loading-shimmer">
            <LoadingShimmer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Editproduct;
