import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Logins from '../component/login/Login'
import NavBar from '../component/navbar/NavBar'

const Login = () => {

  const navigate = useNavigate();

  const userid = localStorage.getItem('userid')
  useEffect(()=>{
    if(userid){
    navigate("/")
    }
  },[])
  
  return (
    <div>
        <NavBar/>
        <Logins />
    </div>
  )
}

export default Login