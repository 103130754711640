import React, { useEffect, useState } from "react";
import "./catagories.css";
import Catagoriesitem from "./Catagoriesitem";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Catagories = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [adsBanner, setAdsBanner] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get("/api/getcatagory");
        setData(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get("/api/getButtonTrueAdsBanner");
        console.log(res.data);
        setAdsBanner(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  // Skeleton component for loading state
  const Skeleton = () => (
    <div className="ads-banner-wrapper">
      <div className="banner-skeleton-image"></div>
    </div>
  );

  return (
    <>
      {/* <p className="home-products-title">Categories and sections</p> */}
      <>
        {loading ? (
          <Skeleton /> // Render skeleton if loading
        ) : (
          <>
            {/* Actual content */}
            <div className="categories-big-sale-main-wrapper">
              <div className="Categories-Container">
                {data &&
                  data.map((item) => (
                    <Catagoriesitem item={item} key={item?.id} />
                  ))}
              </div>
            </div>
            <div className="ads-banner-wrapper">
              {adsBanner && (
                <div className="big-sale-poster">
                  <img
                    src={adsBanner.image}
                    alt="ADS"
                    onClick={() => navigate(`${adsBanner?.url}`)}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    </>
  );
};

export default Catagories;
