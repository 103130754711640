import React from 'react'
import Logins from '../../component/admin/login/Login'

const Login = () => {
  return (
    <div>
        <Logins/>
    </div>
  )
}

export default Login