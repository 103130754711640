// import axios from "../../../axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import "./bannermng.css";
// import LoadingShimmer from "../loadingshimmer/LoadingShimmer";

// const Addbannermng = () => {
//   const navigate = useNavigate();
//   const params = useParams();

//   const [title, setTitle] = useState();
//   const [detail, setDetail] = useState();
//   const [button, setButton] = useState();
//   const [url, setUrl] = useState();
//   const [image, setImage] = useState();
//   const [baseImage, setBaseImage] = useState("");
//   const [isLoading, setIsLoading] = useState(false);


//   const uploadImage = async (e) => {
//     const file = e.target.files[0];
//     const base64 = await convertBase64(file);
//     setBaseImage(base64);
//   };

//   const convertBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.readAsDataURL(file);

//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };

//       fileReader.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   const addbanner = async () => {
//     setIsLoading(true);
//     try {
//       const newBanner = {
//         title,
//         detail,
//         url,
//         button,
//         image: baseImage,
//       };

//       let res = await axios.post("/api/admin/addbanner", newBanner,{withCredentials:true});
//       if (res) {
//         navigate("/admin/showbanner");
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false); // Set loading to false when the function finishes
//     }
//   };

//   useEffect(() => {
//     if (params.id) {
//       (async () => {
//         try {
//           let res = await axios.get(
//             `/api/admin/getbannerdata/${params.id}`,
//             {withCredentials:true}
//           );
//           setTitle(res.data.title);
//           setDetail(res.data.detail);
//           setUrl(res.data.url);
//           setButton(res.data.button);
//           setImage(res.data.image);
//         } catch (error) {
//           console.log(error);
//         }
//       })();
//     }
//   }, []);

//   const updatebanner = async () => {
//     try {
//       const newbanner = {
//         title,
//         detail,
//         button,
//         url,
//         image: baseImage,
//       };

//       let res = await axios.post(
//         `/api/admin/updatebanner/${params.id}`,
//         newbanner,{withCredentials:true}
//       );
//       if (res) {
//         navigate("/admin/showbanner");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <>
//       <div className="addbanner-container">
//         <div className="addbanner-box">
//           <div className="addbanner-box-title">
//             <div
//               onClick={() => navigate("/admin/showbanner")}
//               className="addbanner-viewbutton"
//             >
//               <button>View Banner</button>
//             </div>
//             <h1>Add Banner</h1>
//           </div>

//           <div className="addbanner-box-data">
//             <text>Banner Title</text>
//             <input value={title} onChange={(e) => setTitle(e.target.value)} />
//           </div>
//           <div className="addbanner-box-data">
//             <text>Banner Detail</text>
//             <input value={detail} onChange={(e) => setDetail(e.target.value)} />
//           </div>
//           <div className="addbanner-box-data">
//             <text>URL</text>
//             <input value={url} onChange={(e) => setUrl(e.target.value)} />
//           </div>
//           <div className="addbanner-box-data">
//             <text>Button Name</text>
//             <input value={button} onChange={(e) => setButton(e.target.value)} />
//           </div>

//           <div className="addbanner-box-data">
//             <text>Image 1</text>
//             {baseImage ? (
//               <img className="addbanner-img" src={baseImage} alt="image" />
//             ) : (
//               <img className="addbanner-img" src={image} alt="image" />
//             )}
//             <input
//               type="file"
//               onChange={(e) => {
//                 uploadImage(e);
//               }}
//             />
//           </div>

//           <div className="addbanner-box-button">
//             {params.id ? (
//               <button onClick={updatebanner}>Update Banner</button>
//             ) : (
//               <button onClick={addbanner}>Add Banner</button>
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="loading-shimmer-add-product">
//         {isLoading && (
//           <div className="loading-shimmer">
//             <LoadingShimmer />
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Addbannermng;









import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../axios";
import "./bannermng.css";
import LoadingShimmer from "../loadingshimmer/LoadingShimmer";
import imageCompression from "browser-image-compression"; // Import image compression library

const Addbannermng = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [button, setButton] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [baseImage, setBaseImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle image upload and compression
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    
    // Configure compression options
    const options = {
      maxSizeMB: 0.2, // Max size in megabytes
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true, // Use web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const base64 = await convertBase64(compressedFile);
      setBaseImage(base64); // Set compressed base64 image to state
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  // Convert file to base64 format
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Function to add a new banner
  const addBanner = async () => {
    setIsLoading(true);
    try {
      const newBanner = {
        title,
        detail,
        url,
        button,
        image: baseImage, // Use compressed image data
      };

      let res = await axios.post("/api/admin/addbanner", newBanner, { withCredentials: true });
      if (res) {
        navigate("/admin/showbanner");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to update an existing banner
  const updateBanner = async () => {
    try {
      const updatedBanner = {
        title,
        detail,
        button,
        url,
        image: baseImage, // Use compressed image data
      };

      let res = await axios.post(`/api/admin/updatebanner/${params.id}`, updatedBanner, { withCredentials: true });
      if (res) {
        navigate("/admin/showbanner");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch banner data if editing
  useEffect(() => {
    if (params.id) {
      (async () => {
        try {
          let res = await axios.get(`/api/admin/getbannerdata/${params.id}`, { withCredentials: true });
          setTitle(res.data.title);
          setDetail(res.data.detail);
          setUrl(res.data.url);
          setButton(res.data.button);
          setImage(res.data.image);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [params.id]);

  return (
    <>
      <div className="addbanner-container">
        <div className="addbanner-box">
          <div className="addbanner-box-title">
            <div onClick={() => navigate("/admin/showbanner")} className="addbanner-viewbutton">
              <button>View Banner</button>
            </div>
            <h1>{params.id ? 'Edit Banner' : 'Add Banner'}</h1>
          </div>

          <div className="addbanner-box-data">
            <text>Banner Title</text>
            <input value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="addbanner-box-data">
            <text>Banner Detail</text>
            <input value={detail} onChange={(e) => setDetail(e.target.value)} />
          </div>
          <div className="addbanner-box-data">
            <text>URL</text>
            <input value={url} onChange={(e) => setUrl(e.target.value)} />
          </div>
          <div className="addbanner-box-data">
            <text>Button Name</text>
            <input value={button} onChange={(e) => setButton(e.target.value)} />
          </div>

          <div className="addbanner-box-data">
            <text>Image</text>
            {baseImage ? (
              <img className="addbanner-img" src={baseImage} alt="Selected" />
            ) : (
              <img className="addbanner-img" src={image} alt="Current" />
            )}
            <input type="file" onChange={handleImageUpload} /> {/* NEW CODE HERE */}
          </div>

          <div className="addbanner-box-button">
            {params.id ? (
              <button onClick={updateBanner}>Update Banner</button>
            ) : (
              <button onClick={addBanner}>Add Banner</button>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loading-shimmer-add-product">
          <div className="loading-shimmer">
            <LoadingShimmer />
          </div>
        </div>
      )}
    </>
  );
};

export default Addbannermng;
