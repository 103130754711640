import React, { useEffect, useState } from 'react';
import ShowAdsBanner from '../../component/admin/adsBanner/ShowAdsBanner';
import AddAdsBanner from '../../component/admin/adsBanner/AddAdsBanner';
import Navbar from '../../component/admin/navbar/Navbar';
import axios from '../../axios';

const AdsBannerPage = () => {

  const [banners, setBanners] = useState([]);
  const [refresh,setRefresh] = useState(true)

  useEffect(()=>{
    (async()=>{
      try{
        let res = await axios.get('/api/admin/getalladsbanner',{withCredentials:true})
        console.log(res.data)
        setBanners(res.data)
      }catch(error){
        console.log(error)
      }
    })()
  },[refresh])

  const addBanner = async (newBanner) => {
    try {
      await axios.post('/api/admin/addadsbanner', newBanner,{withCredentials:true});
      setBanners([...banners, newBanner]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBanner = async (id) => {
      try{
      await axios.delete(`/api/admin/deleteadsbaner/${id}`,{withCredentials:true})
      setRefresh(!refresh)
    }catch(error){
      console.log(error)
    }
  };


  const RadioButtonChange = async (id) => {
    try{
     await axios.put(`/api/admin/setAdsBannerShow/${id}`,{},{withCredentials:true});
    setRefresh(!refresh)
  }catch(error){
    console.log(error)
  }
};
  

  return (
    <div>
        <Navbar />
      <ShowAdsBanner banners={banners} deleteBanner={deleteBanner} RadioButtonChange={RadioButtonChange} />
      <AddAdsBanner  addBanner={addBanner}/>
    </div>
  )
}

export default AdsBannerPage
