import React, { useEffect } from "react";
import NavBar from "../component/navbar/NavBar";
import Banner from "../component/banner/Banner";
import Catagories from "../component/catagories/Catagories";
import Footer from "../component/footer/Footer";
import Homeproducts from "../component/homeproductlisting/Homeproducts";
import "./home.css";
import axios from "../axios";
import { toast } from 'react-toastify';
const Home = () => {

  const getUser = async () => {
    try {
        const res = await axios.get('/auth/login/success', {withCredentials: true})
        localStorage.setItem("userid",res.data._id)
    } catch (error) {
      toast.error(error?.data?.message || error?.error)
    }
}

  useEffect(() => {
    getUser()
}, [])

  return (
    <div>
      <NavBar />
      <div className="wrapper-for-banner-and-home-products">
        <div className="banner-main-wrapper">
          <Banner />
        </div>
        <div className="home-products-main-wrapper">
          <p className="popular-books-title">Popular Books</p>
          <Homeproducts />
        </div>
      </div>
      <Catagories />
      {/* <div>
        <p className="home-products-title">New Books</p>
        <Homeproducts />
      </div> */}
      {/* <div className="new-books-container">
        <p className="home-products-title">New Books</p>
        <Homeproducts />

        <div className="new-books-container">
        <p className="home-products-title">Journals</p>
        <Homeproducts />
      </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Home;
