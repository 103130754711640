import React from 'react'
import Navbar from '../../component/admin/navbar/Navbar'
import Box from '../../component/admin/chart/Box'

const Home = () => {
  return (
    <div>

        <Navbar />
        <Box />
        
    </div>
  )
}

export default Home