import axios from "../../../axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./catagorymng.css";
import LoadingShimmer from "../loadingshimmer/LoadingShimmer";
import imageCompression from "browser-image-compression";

const Addcatagorymng = () => {
  const [catagoryName, setCatagoryName] = useState();
  const [image, setImage] = useState();
  const [catbanner, setCatbanner] = useState();
  const [baseImage1, setBaseImage1] = useState("");
  const [baseImage2, setBaseImage2] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const handleImageUpload1 = async (e) => {
    const file = e.target.files[0];
    
    // Configure compression options
    const options = {
      maxSizeMB: 0.1, // Max size in megabytes
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true, // Use web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const base64 = await convertBase64(compressedFile);
      setBaseImage1(base64); // Set compressed base64 image to state
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  const handleImageUpload2 = async (e) => {
    const file = e.target.files[0];
    
    // Configure compression options
    const options = {
      maxSizeMB: 0.1, // Max size in megabytes
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true, // Use web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const base64 = await convertBase64(compressedFile);
      setBaseImage2(base64); // Set compressed base64 image to state
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addcatagory = async () => {
    setIsLoading(true);
    try {
      const newcatagory = {
        name: catagoryName,
        image: baseImage1,
        catbanner: baseImage2, 
      };
      let res = await axios.post("/api/admin/addcatagory", newcatagory,{withCredentials:true});
      if (res) {
        navigate("/admin/showcatagory");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Set loading to false when the function finishes
    }
  };

  const updatecatagory = async () => {
    try {
      const newcatagory = {
        name: catagoryName,
        image: baseImage1,
        catbanner: baseImage2,
      };

      let res = await axios.post(
        `/api/admin/updatecatagory/${params.id}`,
        newcatagory,
        {withCredentials:true}
      );
      if (res) {
        navigate("/admin/showcatagory");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (params.id) {
      (async () => {
        try {
          let res = await axios.get(`/api/admin/catagorydata/${params.id}`,{withCredentials:true});
          setCatagoryName(res.data.name);
          setImage(res.data.image);
          setCatbanner(res.data.catbanner);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  return (
    <>
    <div className="addcatagory-container">
      <div className="addcatagory-box">
        <div className="addcatagory-box-title">
          <div
            onClick={() => navigate("/admin/showcatagory")}
            className="addcatagory-viewbutton"
          >
            <button>View Catagory</button>
          </div>
          <h1>Add Catagory</h1>
        </div>

        <div className="addcatagory-box-data">
          <text>Catagory Name</text>
          <input
            value={catagoryName}
            onChange={(e) => setCatagoryName(e.target.value)}
          />
        </div>

        <div className="addcatagory-box-data">
          <text>Image</text>
          {baseImage1 ? (
            <img className="addcatagory-img" src={baseImage1} alt="image" />
          ) : (
            <img className="addcatagory-img" src={image} alt="image" />
          )}
          <input
            type="file"
            onChange={(e) => {
              handleImageUpload1(e);
            }}
          />
        </div>
        <div className="addcatagory-box-data">
          <text>Catagory Banner</text>
          {baseImage2 ? (
            <img className="addcatagory-img" src={baseImage2} alt="image" />
          ) : (
            <img className="addcatagory-img" src={catbanner} alt="image" />
          )}
          <input
            type="file"
            onChange={(e) => {
              handleImageUpload2(e);
            }}
          />
        </div>

        <div className="addcatagory-box-button">
          {params.id ? (
            <button onClick={updatecatagory}>Update Catagory</button>
          ) : (
            <button onClick={addcatagory}>Add Catagory</button>
          )}
        </div>
      </div>
    </div>
    <div className="loading-shimmer-add-product">
   {isLoading && <div className="loading-shimmer">
     <LoadingShimmer/>
     </div>}
   </div>
    </>

  );
};

export default Addcatagorymng;
