import React from 'react'
import Showbannermngs from '../../component/admin/bannermng/Showbannermng'
import Navbar from '../../component/admin/navbar/Navbar'

const Showbannermng = () => {
  return (
    <div>
        <Navbar />
        <Showbannermngs />
    </div>
  )
}

export default Showbannermng