import React, { useEffect, useState } from 'react';
import Navbar from '../../component/admin/navbar/Navbar';
import ShowDeliveryCharge from '../../component/admin/deliveryCharge/ShowDeliveryCharge';
import AddDeliveryCharge from '../../component/admin/deliveryCharge/AddDeliveryCharge';
import axios from '../../axios';

const DeliveryChargePage = () => {

  const [data,setData] = useState([]);
  const [refresh,setRefresh] = useState(true);


  useEffect(()=>{
    (async()=>{
      try{
        let res = await axios.get('/api/admin/getalldeliverycharge',{withCredentials:true})
        console.log(res.data)
        setData(res.data)
      }catch(error){
        console.log(error)
      }
    })()
  },[refresh])

  const addCharge = async (newCharge) => {
    try {
      const res = await axios.post('/api/admin/adddeliverycharge', newCharge,{withCredentials:true});
      setRefresh(!refresh)
      return res
    } catch (error) {
      return error.response
    }
  };

  const deleteCharge = async (id) => {
    try{
    await axios.delete(`/api/admin/deleteCharge/${id}`,{withCredentials:true})
    setRefresh(!refresh)
  }catch(error){
    console.log(error)
  }
};
  
  return (
    <div>
      <Navbar />
      <ShowDeliveryCharge data={data} deleteCharge={deleteCharge} />
      <AddDeliveryCharge addCharge={addCharge} />
    </div>
  )
}

export default DeliveryChargePage
