import React from "react";
import "../../component/contactus/contactus.css";
import Footer from "../footer/Footer";
import NavBar from "../navbar/NavBar";
const ContactUs = () => {
  return (
    <>
      <NavBar />

      <div className="contact-us-privacy-policy-and-other-main-wrapper">
        <h4>Get in Touch</h4>

        <p>
          We value your feedback, inquiries, and suggestions. Feel free to reach
          out to us using any of the methods below. We strive to respond to all
          messages promptly.
        </p>

        <h5>Contact Information:</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">
                Operating Address:
              </span>{" "}
              Inkista, Puthuppadi, Thamarassery, Kozhikode, Kerala, India,
              673586
            </p>
          </span>
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">Phone:</span>{" "}
              +91 9847660300
            </p>
          </span>
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">Email:</span>{" "}
              support@inkista.gmail.com
            </p>
          </span>
        </p>

        <h5>Social Media :</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            <p>
              Connect with us on social media platforms for updates, news, and
              discussions:
            </p>
          </span>

          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">
                Instagram:
              </span>
              <a
                className="social-media-link"
                href="https://www.instagram.com/inkista.in?igsh=MWtybXZ1em42NGQ2Zg%3D%3D"
                target="_blank"
              >
                inkista.in
              </a>
            </p>
          </span>
        </p>

        <h5> Customer Support:</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            For assistance with orders, products, or any other inquiries, our
            customer support team is here to help. Email us at
            support@inkista.gmail.com for assistance.
          </span>
        </p>

        <h5> Feedback:</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            We greatly appreciate your feedback! Whether it’s praise or
            constructive criticism, we’re always looking to improve. Please
            share your thoughts with us via email at support@inkista.gmail.com
          </span>
        </p>
      </div>
      <div className="bottom-title-contact-us-and-all">
        <h5>We look forward to hearing from you!</h5>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
