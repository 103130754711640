import React from 'react'
import Addproducts from '../../component/admin/productmng/Addproduct'
import Navbar from '../../component/admin/navbar/Navbar'

const Addproduct = () => {
  return (
    <div>
        <Navbar />
        <Addproducts />
    </div>
  )
}

export default Addproduct