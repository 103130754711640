import React, { useEffect, useState } from 'react';
import './ShowReviews.css';
import { useNavigate } from "react-router-dom";
import axios from "../../../../axios";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Delete from '../../confirmation/Delete';

const ShowReviews = () => { 

  const navigate = useNavigate();
  const params = useParams();

  const [review, setReview] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/api/getallreview/${params.id}`);
        const reviews = res.data.review;
        setReview(reviews);
        setReviewCount(reviews.length);

        // Calculate average rating for reviews with mode === true
        const filteredReviews = reviews.filter(review => review.mode);
        const totalRating = filteredReviews.reduce((acc, curr) => acc + parseFloat(curr.rating), 0);
        const avgRating = filteredReviews.length > 0 ? totalRating / filteredReviews.length : 0;
        setAverageRating(avgRating);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [params.id]);

  const handleDelete = async (reviewId) => {
    try {
      const res = await axios.delete(`/api/admin/deletereview/${params.id}/${reviewId}`, { withCredentials: true });
  
      const updatedReviews = review.filter(r => r._id !== reviewId);
      setReview(updatedReviews);
      setReviewCount(updatedReviews.length);

      // Recalculate average rating for reviews with mode === true
      const filteredReviews = updatedReviews.filter(review => review.mode);
      const totalRating = filteredReviews.reduce((acc, curr) => acc + parseFloat(curr.rating), 0);
      const avgRating = filteredReviews.length > 0 ? totalRating / filteredReviews.length : 0;
      setAverageRating(avgRating);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleMode = async (reviewId, currentMode) => {
    try {
      const newMode = !currentMode;
      await axios.patch(`/api/admin/togglemodereview/${params.id}/${reviewId}`, { mode: newMode }, { withCredentials: true });
      const updatedReviews = review.map(r => r._id === reviewId ? { ...r, mode: newMode } : r);
       setReview(updatedReviews);

      // Recalculate average rating for reviews with mode === true
      const filteredReviews = updatedReviews.filter(review => review.mode);
      const totalRating = filteredReviews.reduce((acc, curr) => acc + parseFloat(curr.rating), 0);
      const avgRating = filteredReviews.length > 0 ? totalRating / filteredReviews.length : 0;
      setAverageRating(avgRating);
    } catch (err) {
      console.log(err);
    }
  };

  const openConfirmModal = (reviewId) => {
    setSelectedReviewId(reviewId);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setSelectedReviewId(null);
    setShowConfirmModal(false);
  };

  const confirmDelete = () => {
    if (selectedReviewId) {
      handleDelete(selectedReviewId);
    }
    closeConfirmModal();
  };

  return (
    <div className="ShowReview-container">
    <div className="ShowReview-box">
      <div
        className="ShowReview-addproduct-btn"
        onClick={() => navigate("/admin/showproduct")}
      >
        <button>View Product</button>
      </div>
      <h1 className="ShowReview-title">PRODUCTS</h1>

      <div className="ShowReview-summary">
          <p>Total Reviews: <span>{reviewCount}</span></p>
          <p>
            Average Rating: 
            <Rating
              name="simple-controlled"
              precision={0.5}
              value={averageRating.toFixed(1)}
              readOnly
              className="ShowReview-rating"
            />
          </p>
        </div>

      <div className="ShowReview-divtable">
        <table className="ShowReview-table" id="myTable">
          <thead>
            <tr>
              <th>User name</th>
              <th>Rating</th>
              <th>Rview</th>
              <th>Mode</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {review?.map((item, index) => (
              <tr>
                <td>{item.username}</td>
                <td>
                <Rating
                          name="simple-controlled"
                          precision={0.5}
                          value={item.rating}
                          readOnly
                        />
                </td>
                <td>{item.description}</td>
                <td>
                <button onClick={() => toggleMode(item._id, item.mode)}>
                      {item.mode ? "Turn Off" : "Turn On"}
                    </button>
                </td>
                <td>
                  <button onClick={() => openConfirmModal(item._id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Delete
        show={showConfirmModal}
        handleClose={closeConfirmModal}
        handleConfirm={confirmDelete}
      />
  </div>
  )
}

export default ShowReviews;
