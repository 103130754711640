import React, { useEffect, useRef, useState } from "react";
import "./productdetail.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import ProductNotFound from "./productNotFound";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { trackMetaPixelEvent } from "../../tracking/FacebookPixelEvent";

const Productdetail = () => {
  const params = useParams();
  const messagesEndRef = useRef();
  const navigate = useNavigate();

  const [detail, setDetail] = useState({});
  const [viewimage, setViewimage] = useState();
  const [cartdata, setCartdata] = useState();
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const [media, setMedia] = useState([]);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const user = localStorage.getItem("userid");

  const differencePrice = Math.abs(
    detail?.retailprice - detail?.wholesaleprice
  );
  const percentageSaved = Math.round(
    (differencePrice / detail?.wholesaleprice) * 100
  );

  const AvailableOrOutOfStockStatus = detail.stock;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const handleToggle = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  useEffect(() => {
    
    scrollToBottom();
    // addToCartPixel();
  }, [error]);
  const isDisabled = AvailableOrOutOfStockStatus < 0;

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axios.get(`/api/getproductdetail/${params.id}`);
  //       setDetail(res.data);
  //       // setViewimage(res.data.images?.[0] || res.data.image1); // Default to the first image
  //       setMedia([...res.data.images, ...res.data.videos]);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(`/api/getproductdetail/${params.id}`);
        // addToCartPixel();
        setDetail(res.data);
        // Separate images and videos
        const images = res.data.images || [];
        const videos = res.data.videos || [];
        setMedia([...images, ...videos]);

        setLoading(false);
        // trackMetaPixelEvent('Product Detail Page', { button: 'Product Page' });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [params.id]);

  useEffect(() => {
    (async () => {
      try {
        let cartId = Cookies.get("cartId");
        if (user) {
          let res = await axios.get(`/api/getcartitem/${user}`, {
            withCredentials: true,
          });
          setCartdata(res.data?.cartitem);
        } else if (cartId) {
          let res = await axios.get(`/api/getcartitem/guest/${cartId}`);
          console.log(res);
          setCartdata(res.data?.cartitem);
        } else {
          setCartdata([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refresh]);

  const gotocart = () => {
    setRefresh(!refresh);
    // trackMetaPixelEvent('AddToCart', { button: 'Add To Cart' });

    navigate("/cart");
  };

  const addtocart = async (productid, type) => {
    const qty = 1;
    trackMetaPixelEvent('AddToCart', { button: 'add to cart' });

    try {
     
      let cartId = Cookies.get("cartId");

      if (!cartId) {
        
        cartId = uuidv4(); // generate a new unique ID
        Cookies.set("cartId", cartId, {
          expires: 7,
          secure: true,
          sameSite: "strict",
        }); // set cookie for 7 days
      }

      if (user) {
        let res = await axios.post(
          `/api/addtocart/${user}/${qty}`,
          {
            productid,
          },
          { withCredentials: true }
        );
        if (type === "cart") {
          setRefresh(!refresh);

        } else {
          navigate("/cart");
        }
      } else {
        let res = await axios.post(`/api/addtocart/guest/${cartId}/${qty}`, {
          productid,
        });
        if (type === "cart") {
          setRefresh(!refresh);
        } else {
          navigate("/cart");
          setRefresh(!refresh);
        }
      }
      // addToCartPixel();
      //  // Track 'AddToCart' event with Facebook Pixel
      //  if (typeof fbq !== 'undefined') {
      //   fbq('track', 'AddToCart');
      // } else {
      //   console.log('Facebook Pixel script not loaded');
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const alert = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  return (
    <>
      {loading ? (
        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
          <div className="productdetail-container">
            <Skeleton height={400} />
            <Skeleton count={5} />
          </div>
        </SkeletonTheme>
      ) : detail ? (
        <>
          {error && (
            <div ref={messagesEndRef} className="products-box-error-detail">
              <p>Please make login</p>
            </div>
          )}

          {loading ? (
            <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
              <div className="productdetail-container">
                <Skeleton height={400} />
                <Skeleton count={5} />
              </div>
            </SkeletonTheme>
          ) : (
            <div className="productdetail-container">
              <div className="productdetail-image-container-mb">
                <div className="productdetail-image-container-view-mb">
                  {media[selectedMediaIndex]?.endsWith(".mp4") ? (
                    <video
                      // controls
                      src={media[selectedMediaIndex]}
                      alt=""
                      autoPlay
                    />
                  ) : (
                    <img src={media[selectedMediaIndex]} alt="viewimg" />
                  )}
                </div>
                <div className="productdetail-image-container-list-mb">
                  {media.map((mediaItem, index) => (
                    <div
                      key={index}
                      onClick={() => setSelectedMediaIndex(index)}
                    >
                      {mediaItem.endsWith(".mp4") ? (
                        <video
                          src={mediaItem}
                          alt={`${detail.name} ${index + 1}`}
                        />
                      ) : (
                        <img
                          src={mediaItem}
                          alt={`${detail.name} ${index + 1}`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Description and Actions */}
              <div className="productdetail-desc-container">
                <p className="productdetail-desc-container-title">
                  {detail?.name}
                </p>

                <div className="productdetail-desc-container-price">
                  <p>{detail?.pointone}</p>
                </div>

                <div className="productdetail-desc-container-price">
                  <p className="productdetail-desc-container-retailprice">
                    ₹ {detail?.retailprice}
                  </p>
                </div>

                {detail?.wholesaleprice > 0 && (
                  <>
                    <div className="productdetail-desc-container-price">
                      <p className="productdetail-desc-container-retailprice mr-price">
                        <del>M.R.P ₹{detail?.wholesaleprice}</del>
                      </p>
                    </div>
                    <div className="productdetail-desc-container-price">
                      <p className="productdetail-desc-container-retailprice mr-price">
                        Save: ₹{differencePrice} ({percentageSaved}%)
                      </p>
                    </div>
                  </>
                )}

                <div className="productdetail-desc-container-price">
                  {AvailableOrOutOfStockStatus > 0 ? (
                    <p className="stock-available-text">Available</p>
                  ) : (
                    <p className="stock-out-text">Out Of Stock</p>
                  )}
                </div>

                <div>
                  <p
                    className="productdetail-desc-container-text"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {detail?.detail}
                  </p>
                </div>

                <div>
                  <ul
                    className="productdetail-desc-container-points"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {detail?.pointtwo}
                  </ul>
                </div>

                <div className="productDetials-wrapper">
                  <div>
                    <p onClick={() => handleToggle("color")}>
                      Product Features
                    </p>
                    <ul
                      className={`productdetail-desc-container-points ${
                        openSections.color ? "open" : ""
                      }`}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {detail?.color}
                    </ul>
                  </div>

                  <div>
                    <p onClick={() => handleToggle("material")}>
                      Inside the packing
                    </p>
                    <ul
                      className={`productdetail-desc-container-points ${
                        openSections.material ? "open" : ""
                      }`}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {detail?.material}
                    </ul>
                  </div>

                  <div className="whats-inside-para">
                    <p onClick={() => handleToggle("pointfour")}>
                      What’s Inside
                    </p>
                    <ul
                      className={`productdetail-desc-container-points ${
                        openSections.pointfour ? "open" : ""
                      }`}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {detail?.pointfour}
                    </ul>
                  </div>
                </div>

                <div className="productdetail-desc-container-button">
                  {cartdata &&
                  cartdata.some(
                    (cartitem) => cartitem?.itemid === detail?._id
                  ) ? (
                    <button
                      className={`productdetail-desc-container-buynow ${
                        isDisabled ? "button-disabled" : ""
                      }`}
                      value={detail?._id}
                      onClick={gotocart}
                      disabled={isDisabled}
                    >
                      BUY NOW
                    </button>
                  ) : (
                    <button
                      className={`productdetail-desc-container-buynow ${
                        isDisabled ? "button-disabled" : ""
                      }`}
                      value={detail?._id}
                      onClick={(e) => addtocart(e.target.value, "buy")}
                      disabled={isDisabled}
                    >
                      BUY NOW
                    </button>
                  )}
                  {cartdata &&
                  cartdata.some(
                    (cartitem) => cartitem?.itemid === detail?._id
                  ) ? (
                    <button
                      className={`productdetail-desc-container-addcart ${
                        isDisabled ? "button-disabled" : ""
                      }`}
                      value={detail?._id}
                      onClick={gotocart}
                      disabled={isDisabled}
                    >
                      GO TO CART
                    </button>
                  ) : (
                    <button
                      className={`productdetail-desc-container-addcart ${
                        isDisabled ? "button-disabled" : ""
                      }`}
                      value={detail?._id}
                      onClick={(e) => addtocart(e.target.value, "cart")}
                      disabled={isDisabled}
                    >
                      ADD TO CART
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          <div>
            <div className="about-product">
              <h5>More</h5>
              {detail?.pointthree}
            </div>
          </div>
        </>
      ) : (
        <ProductNotFound />
      )}
    </>
  );
};

export default Productdetail;
