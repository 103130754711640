import React from 'react';
import Navbar from '../../component/admin/navbar/Navbar';
import ShowReviews from '../../component/admin/productmng/ShowReviews/ShowReviews';

const GetReviews = () => {
  return (
    <div>
       <Navbar />
       <ShowReviews />
    </div>
  )
}

export default GetReviews
