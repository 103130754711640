import React from "react";
import "./LoadingShimmer.css";

const LoadingShimmer = () => {
  return (
    <div className="loader-wrapper">
      <div class="loader"></div>
    </div>
  );
};

export default LoadingShimmer;
