import React from 'react';
import "./DeliveryCharge.css";

const DeliveryCharge = ({data,deleteCharge}) => {

  const handleDelete = async(id) =>{
    deleteCharge(id)
  }

  return (
    <div className='deliverychargeshow-container'>
    <div className='deliverychargeshow-box'>
      <h1 className='deliverychargeshow-title'>DELIVERY CHARGE</h1>
    
<div className='deliverychargeshow-divtable'>
<table className='deliverychargeshow-table'>
<tr>
  <th>Price Range</th>
  <th>Delivery Charge</th>
  <th>Action</th>
</tr>

{data.map((item)=>(
 <tr>
 <td>{item.priceGreater} TO {item.priceLesser}</td>
 <td>{item.charge}</td>
 <td>
   <button value={item._id} onClick={(e)=>handleDelete(e.target.value)} >Delete</button>
 </td>
</tr>
))}
 

</table>
</div>

    </div>
      
  </div>
  )
}

export default DeliveryCharge
