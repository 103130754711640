import React from "react";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios";
import Logo from "../../../assets/logo/Inkista-logo.png";

const { useState } = React;

const Navbar = () => {
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const navigate = useNavigate();

  const logout = async () => {
    // Display confirmation alert
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    // If user confirms logout
    if (confirmLogout) {
      try {
        const res = await axios.get("/api/admin/logout", { withCredentials: true });
        localStorage.removeItem("admin");
        navigate("/admin");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="nav-container">
          {isOpen ? (
            <></>
          ) : (
            <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md">
              <div className="container-fluid p-2">
                <a className="navbar-brand text-primary mr-0">
                  <img className="logo-image" src={Logo} alt="MyInkista" />
                </a>
                <div className="form-inline ml-auto">
                  <div className="btn btn-primary" onClick={ToggleSidebar}>
                    <i className="fa fa-bars"></i>
                  </div>
                </div>
              </div>
            </nav>
          )}
        </div>
        <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
          <div className="sd-header">
            {/* <h4 className="mb-0">MyInkista</h4> */}
            <img className="logo-image" src={Logo} alt="MyInkista" />

            <div className="btn btn-primary" onClick={ToggleSidebar}>
              <i className="fa fa-times"></i>
            </div>
          </div>

          <div className="sd-body">
            <ul>
              <li onClick={() => navigate("/admin/usermanagment")}>
                <a className="sd-link">User Managment</a>
              </li>
              <li onClick={() => navigate("/admin/showproduct")}>
                <a className="sd-link">Product Managment</a>
              </li>
              <li onClick={() => navigate("/admin/showcatagory")}>
                <a className="sd-link">Catagory Management</a>
              </li>
              <li onClick={() => navigate("/admin/showbanner")}>
                <a className="sd-link">Banner Management</a>
              </li>
              <li onClick={() => navigate("/admin/adsbanner")}>
                <a className="sd-link">Ads Banner</a>
              </li>
              <li onClick={() => navigate("/admin/orders")}>
                <a className="sd-link">Order Management</a>
              </li>
              <li onClick={() => navigate("/admin/deliverycharge")}>
                <a className="sd-link">Delivery Charge</a>
              </li>

              <li onClick={logout}>
                <a className="sd-link">Logout</a>
              </li>
            </ul>
          </div>

          {/* <div className="sd-body">
            <ul>
              <li onClick={() => navigate("/admin/usermanagment")}>
                <a className="sd-link">User Managment</a>
              </li>
              <li onClick={() => navigate("/admin/showproduct")}>
                <a className="sd-link">Product Managment</a>
              </li>
              <li onClick={() => navigate("/admin/showcatagory")}>
                <a className="sd-link">Catagory Management</a>
              </li>
              <li onClick={() => navigate("/admin/showbanner")}>
                <a className="sd-link">Banner Management</a>
              </li>
              <li onClick={() => navigate("/admin/adsbanner")}>
                <a className="sd-link">Ads Banner</a>
              </li>
              <li onClick={() => navigate("/admin/orders")}>
                <a className="sd-link">Order Management</a>
              </li>
              <li onClick={() => navigate("/admin/deliverycharge")}>
                <a className="sd-link">Delivery Charge</a>
              </li>

              <li onClick={logout}>
                <a className="sd-link">Logout</a>
              </li>
            </ul>
          </div> */}
        </div>
        <div
          className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
          onClick={ToggleSidebar}
        ></div>
      </div>
    </>
  );
};

export default Navbar;
