import React from "react";
import "../../component/contactus/contactus.css";
import Footer from "../footer/Footer";
import NavBar from "../navbar/NavBar";
const ShippingAndReturnPolicy = () => {
  return (
    <>
      <NavBar />

      <div className="contact-us-privacy-policy-and-other-main-wrapper">
        <h4>Shipping & Return Policy</h4>
        <p>
          Inkista (“we” and “us”) is the operator of (https://www.inkista.in).
          By placing an order through this website you will be agreeing to the
          terms below. These are provided to ensure both parties are aware of
          and agree upon this arrangement to mutually protect and set
          expectations on our service.
        </p>
        <h5>1. General</h5>
        <p>
          Subject to stock availability. We try to maintain accurate stock
          counts on our website but from time-to-time there may be a stock
          discrepancy and we will not be able to fulfill all your items at time
          of purchase. In this instance, we will fulfill the available products
          to you, and contact you about whether you would prefer to await
          restocking of the backordered item or if you would prefer for us to
          process a refund.
        </p>
        <h5>2. Shipping Costs</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            <p>
              Shipping costs are calculated during checkout based on weight,
              dimensions and destination of the items in the order. Payment for
              shipping will be collected with the purchase. This price will be
              the final price for shipping cost to the customer.
            </p>
          </span>
        </p>
        <h5>3. Delivery Terms</h5>
        <h5>3.1 Transit Time Domestically</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            <p>
              In general, domestic shipments are in transit for 2-12 working
              days
            </p>
          </span>
        </p>
        <h5> 3.2 Dispatch Time</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            Orders are usually dispatched within 2 business days of payment of
            order our warehouse operates on Monday - Saturday during standard
            business hours, except on national holidays at which time the
            warehouse will be closed. In these instances, we take steps to
            ensure shipment delays will be kept to a minimum.
          </span>
        </p>
        <h5> 3.3 Change Of Delivery Address</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            For change of delivery address requests, we are able to change the
            address at any time before the order has been dispatched.
          </span>
        </p>
        <h5> 3.4 P.O. Box Shipping</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            Inkista will ship to P.O. box addresses using postal services only.
            We are unable to offer couriers services to these locations.
          </span>
        </p>
        <h5> 3.5 Military/Restricted Address Shipping</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            Shipping to military/restricted address cannot be committed. Please
            check with us before ordering.
          </span>
        </p>
        <h5>3.6 Items Out Of Stock</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            If delivery time has exceeded the forecasted time, please contact us
            so that we can conduct an investigation.
          </span>
        </p>
        <h5> 4. Parcels Damaged In Transit</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            If you find a parcel is damaged in-transit, if possible, please
            reject the parcel from the courier and get in touch with our
            customer service. If the parcel has been delivered without you being
            present, please contact customer service with next steps.
          </span>
        </p>
        <h5> 5. Cancellations</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            You have the option to cancel your order, provided it has not been
            dispatched from our fulfillment center. To initiate a cancellation,
            please send a cancellation request via mail with your order number.
            Once an order has been dispatched from our fulfillment center, it
            cannot be cancelled. We process orders promptly to ensure timely
            delivery, and this means that once the shipment is in transit,
            cancellation becomes unfeasible.
          </span>
        </p>{" "}
        <h5> 6. Returns and replacements</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            Products once sold can be returned back within 7 day of delivery.
            And return amount will processed within 3 business days after
            return. If you have received a damaged product please write to us at
            myinkista@gmail.com to facilitate replacement of the product, within
            48 hours of receiving the order. We ask that you include unboxing
            video or images of the product showing the damage to help our
            quality control team authenticate the damage claim. Once approved,
            we will initiate the replacement for the product.
          </span>
        </p>
        <h5> 7. Duties & Taxes</h5>
        <h5>7.1 Sales Tax</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            Sales tax has already been applied to the price of the goods as
            displayed on the website
          </span>
        </p>
        <h5> 10. Customer service</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            For all customer service enquiries, please connect us at
            myinkista@gmail.com or +91 9847660300 
          </span>
        </p>
               <p className="titlesfor-contactus-and-others">
          <span>
            Address: Inkista, Puthuppadi, Thamarassery, Kozhikode, Kerala, India, 673586
 </span>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ShippingAndReturnPolicy;
