import React from "react";
import "../../component/contactus/contactus.css";
import Footer from "../footer/Footer";
import NavBar from "../navbar/NavBar";
const privacypolicy = () => {
  return (
    <>
      <NavBar />

      <div className="contact-us-privacy-policy-and-other-main-wrapper">
        <h4>Privacy Policy</h4>
        {/* <h5>Return & Refund Policy:</h5> */}
        <p>
          Inkista recognises the importance of maintaining your privacy. We
          value your privacy and appreciate your trust in us. This Policy
          describes how we treat user information we collect on
          https://www.inkista.in and other offline sources. This Privacy Policy
          applies to current and former visitors to our website and to our
          online customers. By visiting and/or using our website, you agree to
          this Privacy Policy.
        </p>
        <p>
          www.inkista.in is a property of Inkista, a partnership registered
          under the partnership act 1932 having its registered office at
          Inkista, Puthuppadi, Thamarassery, Kozhikode, Kerala, India, 673586
        </p>
        <h5>Information We Collect:</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">
                Contact information:
              </span>{" "}
              We might collect your name, email, mobile number, phone number,
              street, city, state, pincode, country and ip address.
            </p>
          </span>
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">
                Payment and billing information:
              </span>{" "}
              We might collect your billing name, billing address and payment
              method when you buy a ticket. We NEVER collect your credit card
              number or credit card expiry date or other details pertaining to
              your credit card on our website. Credit card information will be
              obtained and processed by our online payment partner Razor Pay.
            </p>
          </span>
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">
                Information you post:
              </span>{" "}
              We collect information you post in a public space on our website
              or on a third-party social media site belonging to
              thejournallab.in.
            </p>
          </span>
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">
                Demographic information:
              </span>{" "}
              We may collect demographic information about you, events you like,
              events you intend to participate in, tickets you buy, or any other
              information provided by your during the use of our website. We
              might collect this as a part of a survey also.
            </p>
          </span>
          <span>
            <p>
              <span className="sub-titiles-for-contact-us-and-all">
                Other information:
              </span>{" "}
              If you use our website, we may collect information about your IP
              address and the browser you’re using. We might look at what site
              you came from, duration of time spent on our website, pages
              accessed or what site you visit when you leave us. We might also
              collect the type of mobile device you are using, or the version of
              the operating system your computer or device is running.
            </p>
          </span>
        </p>
        <h5>We collect information in different ways</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            <span className="sub-titiles-for-contact-us-and-all">
              We collect information directly from you:
            </span>{" "}
            <p>
              We collect information directly from you when you register for an
              event or buy tickets. We also collect information if you post a
              comment on our websites or ask us a question through phone or
              email.
            </p>
          </span>
          <span className="sub-titiles-for-contact-us-and-all">
            We collect information from you passively:
          </span>
          <span>
            <p>
              We use tracking tools like browser cookies for collecting
              information about your usage of our website.
            </p>
          </span>
          <span className="sub-titiles-for-contact-us-and-all">
            We get information about you from third parties:
          </span>
          <span>
            <p>
              For example, if you use an integrated social media feature on our
              websites. The third-party social media site will give us certain
              information about you. This could include your name and email
              address.
            </p>
          </span>
        </p>
        <h5>Use of your personal information</h5>
        <p className="titlesfor-contactus-and-others">
          <span>
            <span className="sub-titiles-for-contact-us-and-all">
              We use information to contact you:
            </span>{" "}
            <p>
              We might use the information you provide to contact you for
              confirmation of a purchase on our website or for other promotional
              purposes.
            </p>
          </span>
          <span className="sub-titiles-for-contact-us-and-all">
            We use information to respond to your requests or questions:
          </span>
          <span>
            <p>
              We might use your information to confirm your registration for an
              event or contest.
            </p>
          </span>
          <span className="sub-titiles-for-contact-us-and-all">
            We use information to improve our products and services:
          </span>
          <span>
            <p>
              We might use your information to customize your experience with
              us. This could include displaying content based upon your
              preferences.
            </p>
          </span>

          <span className="sub-titiles-for-contact-us-and-all">
            We use information to look at site trends and customer interests:
          </span>
          <span>
            <p>
              We may use your information to make our website and products
              better. We may combine information we get from you with
              information about you we get from third parties.
            </p>
          </span>
          <span className="sub-titiles-for-contact-us-and-all">
            We use information for security purposes:
          </span>
          <span>
            <p>
              We may use information to protect our company, our customers, or
              our websites.
            </p>
          </span>
          <span className="sub-titiles-for-contact-us-and-all">
            We use information for marketing purposes:
          </span>
          <span>
            <p>
              We might send you information about special promotions or offers.
              We might also tell you about new features or products. These might
              be our own offers or products, or third-party offers or products
              we think you might find interesting. Or, for example, if you buy
              tickets from us we’ll enroll you in our newsletter.
            </p>
          </span>
          <span className="sub-titiles-for-contact-us-and-all">
            We use information to send you transactional communications:
          </span>
          <span>
            <p>
              We might send you emails or SMS about your account or a ticket
              purchase.
            </p>
          </span>
          <span>
            <p>We use information as otherwise permitted by law.</p>
          </span>

          <h5>Sharing of information with third-parties</h5>
          <span>
            <p>
              We will share information with third parties who perform services
              on our behalf. We share information with vendors who help us
              manage our online registration process or payment processors or
              transactional message processors. Some vendors may be located
              outside of India.
            </p>
          </span>
          <span>
            <p>
              We will share information with the event organizers. We share your
              information with event organizers and other parties responsible
              for fulfilling the purchase obligation. The event organizers and
              other parties may use the information we give them as described in
              their privacy policies.
            </p>
          </span>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default privacypolicy;
