import React from "react";
import "./AdsBanner.css";

const AdsBanner = ({ banners, deleteBanner, RadioButtonChange }) => {
 
  const handleDelete = async (id) => {
    try {
      let confirmed = window.confirm(
        "Are you sure you want to delete this banner?"
      );
      if (confirmed) {
        await deleteBanner(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRadioButtonChange = async (id) => {
    RadioButtonChange(id);
  };

  return (
    <div className="adsahowbanner-container">
      <div className="adsahowbanner-box">
        <h1 className="adsahowbanner-title">ADS</h1>

        <div className="adsahowbanner-divtable">
          <table className="adsahowbanner-table">
            <tr>
              <th>Show button</th>
              <th>Image</th>
              <th>Page Url</th>
              <th>Action</th>
            </tr>
            {banners.map((item) => (
              <tr>
                <td>
                  <input
                    type="radio"
                    name="adsBanner"
                    checked={item.button === "true"}
                    onChange={() => handleRadioButtonChange(item._id)}
                  />
                </td>
                <td>
                  <img className="adsahowbanner-img" src={item.image} />
                </td>
                <td>{item.url}</td>
                <td>
                  <button
                    value={item._id}
                    onClick={(e) => handleDelete(e.target.value)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdsBanner;
