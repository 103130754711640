import React from 'react'
import Usermngs from '../../component/admin/usermng/Usermng'
import Navbar from '../../component/admin/navbar/Navbar'

const Usermng = () => {
  return (
    <div>
        <Navbar />
        <Usermngs />
    </div>
  )
}

export default Usermng