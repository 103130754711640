import React, { useEffect, useRef, useState } from "react";
import "./CheckoutDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import Cookies from "js-cookie";
import AddressGuest from "./AddressGuest/AddressGuest";
import LoadingShimmer from "../admin/loadingshimmer/LoadingShimmer";
// import { trackMetaPixelEvent } from "../../tracking/FacebookPixelEvent";

const CheckoutDetail = () => {
  const [address, setAddress] = useState([]);
  const [cartdata, setCartdata] = useState([]);
  const [grandtotal, setGrandtotal] = useState();
  const [error, setError] = useState(true);
  const [deliveryCharge, setDeliveryCharge] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const messagesEndRef = useRef();

  const userid = localStorage.getItem("userid");

  //getting selected user address for displaying
  useEffect(() => {
    (async () => {
      try {
        setError(true);
        let res = await axios.get(
          `/api/getuseraddres/${userid}/${params.addressid}`,
          { withCredentials: true }
        );
        console.log(res.data);
        setAddress(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  //getting cart item for displaying

  useEffect(() => {
    (async () => {
      try {
        let cartId = Cookies.get("cartId");
        if (userid) {
          let res = await axios.get(`/api/getcartitem/${userid}`, {
            withCredentials: true,
          });
          setCartdata(res.data.cartitem);
          setGrandtotal(res.data.grandtotal);
        } else if (cartId) {
          let res = await axios.get(`/api/getcartitem/guest/${cartId}`);
          setCartdata(res.data.cartitem);
          setGrandtotal(res.data.grandtotal);
        } else {
          setCartdata([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!cartdata) {
      navigate("/");
    }
  }, cartdata);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [error]);

  useEffect(() => {
    (async () => {
      try {
        const deliveryChargeRes = await axios.get("/api/getalldeliverycharge");

        // Extract delivery charge data from the response
        const deliveryCharges = deliveryChargeRes.data;

        // Sort delivery charges by priceLesser value
        deliveryCharges.sort((a, b) => a.priceLesser - b.priceLesser);

        // Find the applicable delivery charge based on grand total
        let applicableCharge = null; // Default charge

        for (let i = 0; i < deliveryCharges.length; i++) {
          const charge = deliveryCharges[i];
          if (
            grandtotal >= charge.priceGreater &&
            grandtotal <= charge.priceLesser
          ) {
            applicableCharge = charge.charge;
            break;
          }
        }

        // Set the delivery charge to the applicable charge
        setDeliveryCharge((prevCharge) => {
          if (prevCharge !== applicableCharge) {
            return applicableCharge;
          }
          return prevCharge;
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [cartdata.grandtotal]);

  const tot = grandtotal;

  // handlePayment Function
  const handlePayment = async () => {
    if (address?.name) {
      setError(true);
      const amount = tot + deliveryCharge;
      try {
        const res = await axios.post(
          "/api/payment/order",
          { amount,address },
          { withCredentials: true }
        );

        handlePaymentVerify(res.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(false);
    }
  };

  // handlePaymentVerify Function
  const handlePaymentVerify = async (data) => {
    const total = tot;
    const options = {
      key: "rzp_live_OlwO4GMy0qbPqV",
      amount: data.amount,
      currency: data.currency,
      name: "Inkista",
      description: "Thanks for buying",
      order_id: data.id,
      handler: async (response) => {
        try {
          const res = await axios.post(
            "/api/payment/verify",
            {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              userid,
              total,
              deliveryCharge,
              cartdata,
              address,
            },
            { withCredentials: true }
          );

          setIsLoading(true);

          console.log("sjdfksd");
          console.log(res.data);
          console.log("sjdfksd");

          if (res.data.message === "Payment Successfully") {
            setIsLoading(false);
            navigate(`/shipping/checkout/sucsess/${res.data.updatedorder._id}`);
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#71447a",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const goToLogin = () => {
    navigate(
      `/signin?returnUrl=${encodeURIComponent(window.location.pathname)}`
    );
  };
  return (
    <div className="checkoutDetail-container">
      {/* address showing container */}
      <div className="checkoutDetail-left-container">
        {userid &&
          (address.name ? (
            <div className="checkoutDetail-address-oneaddress">
              <div className="checkoutDetail-address-dsc">
                <div className="checkoutDetail-address-personal">
                  <text>{address?.name}</text>
                  <text>{address?.mobile}</text>
                </div>
                <div className="checkoutDetail-address-info">
                  <text> {address?.buildingname},</text>
                  <text>{address?.locality},</text>
                  <text>{address?.district},</text>
                  <text>{address?.state}</text>-<text>{address?.pin}</text>
                </div>
              </div>
              <div
                className="checkoutDetail-address-btn"
                onClick={() => navigate("/shipping")}
              >
                <button>Change</button>
              </div>
            </div>
          ) : (
            <>
              {error ? (
                <div className="checkoutDetail-address-oneaddress">
                  <div
                    className="checkoutDetail-address-selectbtn"
                    onClick={() => navigate("/shipping")}
                  >
                    <button>Select Your Address</button>
                  </div>
                </div>
              ) : (
                <div
                  ref={messagesEndRef}
                  className="checkoutDetail-address-oneaddress-error"
                >
                  <div
                    className="checkoutDetail-address-selectbtn"
                    onClick={() => navigate("/shipping")}
                  >
                    <button>Select Your Addres</button>
                  </div>
                </div>
              )}
            </>
          ))}

        {/* product listing  */}
        <div className="checkoutDetail-box-item-list">
          {cartdata &&
            cartdata?.map((item) => (
              <div className="checkoutDetail-box-item">
                <div
                  className="checkoutDetail-box-item-img"
                  onClick={() => navigate(`/productdetail/${item.itemid}`)}
                >
                  <img src={item?.image} alt="img" />
                </div>
                <div className="checkoutDetail-box-item-des">
                  <p className="checkoutDetail-box-item-des-title">
                    {item?.itemname}
                  </p>
                  <p className="checkoutDetail-box-item-des-price">
                    ₹{item?.price}
                  </p>
                  <p>{item.qty}</p>
                </div>
              </div>
            ))}
        </div>

        {!userid && <AddressGuest />}
        {!userid && (
          <div className="checkoutDetail-logincontainer">
            <div className="checkoutDetail-logincontainer_or">OR</div>

            <div>
              <button onClick={goToLogin}>Login with otp</button>
            </div>
          </div>
        )}
      </div>

      {/* amount detail */}
      <div className="checkoutDetail-right-container">
        <p className="checkoutDetail-box-total-title">Order Details</p>
        <div className="checkoutDetail-box-total-detail">
          <p>Price</p>
          <p>₹{grandtotal}</p>
        </div>
        <div className="checkoutDetail-box-total-detail">
          <p>Items</p>
          <p>{cartdata?.length}</p>
        </div>
        <div className="checkoutDetail-box-total-detail">
          <p>Delivery Charges</p>
          {deliveryCharge ? <p>₹{deliveryCharge}</p> : <p>Free</p>}
        </div>

        <div className="checkoutDetail-box-total-detail-amount">
          <p>Total Amount</p>
          <p>₹{grandtotal + deliveryCharge}</p>
        </div>
        {address.name && (
          <div
            className="checkoutDetail-box-total-detail-btn"
            onClick={handlePayment}
          >
            <button>PROCEED TO PAYMENT</button>
          </div>
        )}
      </div>

      <div className="loading-shimmer-add-product">
        {isLoading && (
          <div className="loading-shimmer">
            <LoadingShimmer />
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutDetail;
