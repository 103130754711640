import React from 'react'
import Showcatagorymngs from '../../component/admin/catagorymng/Showcatagorymng';
import Navbar from '../../component/admin/navbar/Navbar';

const Showcatagorymng = () => {
  return (
    <div>
        <Navbar />
        <Showcatagorymngs />
    </div>
  )
}

export default Showcatagorymng