import React, { useState, useEffect } from "react";
import "./Catagoriesitem.css";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ArrowRight from "../../assets/categories-images/arrow-right.png";
const Catagoriesitem = ({ item }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (item) {
      setLoading(false);
    }
  }, [item]);

  function getCategory(item) {
    navigate(`/products/${item}`);
  }

  if (loading) {
    return (
      <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
        <div className="CatagerisItem-MainContainer">
          <Skeleton height={200} width={300} />
          <div className="CatagerisItem-Info">
            <Skeleton width={200} height={20} />
            <Skeleton width={100} height={20} />
          </div>
          <Skeleton height={50} width="100%" />
        </div>
      </SkeletonTheme>
    );
  }

  return (
    <div
      className="CatagerisItem-MainContainer"
      onClick={() => navigate(`/products/${item.name}`)}
    >
      <img className="CatagerisItem-Image" src={item.image} alt="categories" />
      <div className="CatagerisItem-Info">
        <span className="CatagerisItem-Title">{item.name}</span>
        <p
          className="CatagerisItem-Button"
          value={item.name}
          onClick={(e) => getCategory(e.target.value)}
        >
          View All
          <img className="arrow-right-catagories" src={ArrowRight} alt="" />
        </p>
      </div>
      <div className="CatagerisItem-Overlay"></div>
    </div>
  );
};

export default Catagoriesitem;
