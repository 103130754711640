import React, { useEffect, useState } from "react";
import "./Navebar.css";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import SeachIcon from "../../assets/navbar-images/searchicon.png";
import CartIcon from "../../assets/navbar-images/carticon.png";
import ProfileIcon from "../../assets/navbar-images/profileicon.png";
import Logo from "../../assets/logo/Inkista-logo.png";
import Cookies from "js-cookie";

const NavBar = () => {
  const navigate = useNavigate();

  const [togglemenu, setTogglemenu] = useState(false);

  const [cartdata, setCartdata] = useState([]);

  const userid = localStorage.getItem("userid");


  const [data, setData] = useState({});
  const [productData, setProductData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  function togglebutton() {
    if (togglemenu == false) {
      setTogglemenu(true);
    } else {
      setTogglemenu(false);
    }
  }

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Filter productData based on searchInput
  const filteredData = productData.filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const showSearchBar = !window.location.pathname.startsWith("/productdetail");

  function getproductdetail(id) {
    navigate(`/productdetail/${id}`);
  }

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(`/api/userdata/${userid}`, {
          withCredentials: true,
        });
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // console.log(data);

  useEffect(() => {
    (async () => {
      try {
        let cartId = Cookies.get("cartId");
        if (userid) {
          let res = await axios.get(`/api/getcartitem/${userid}`, {
            withCredentials: true,
          });
          console.log(res);
          setCartdata(res.data);
        } else if (cartId) {
          let res = await axios.get(`/api/getcartitem/guest/${cartId}`);
          setCartdata(res.data);
        } else {
          setCartdata([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let res = await axios.get("/api/getsortedproduct");
      setProductData(res.data);
    })();
  }, []);

  const logout = async () => {
    try {
      const res = await axios.get("/api/logout", { withCredentials: true });
      localStorage.removeItem("userid");
      Cookies.remove('jwt');
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const gotocart = () => {
    navigate("/cart");
  };

  const gotoaccount = () => {
    navigate("/accounts/profile");
  };

  return (
    <>
      <div className="navbar-container">
        <nav className="navbar-user">
          <div className="brand-title" onClick={() => navigate("/")}>
            <img className="logo-image" src={Logo} alt="MyInkista" />
          </div>
          <a href="#" onClick={togglebutton} class="toggle-button">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </a>
          {togglemenu ? (
            <div className="navbar-links active">
              <ul>
                {data?.username && (
                  <li onClick={gotoaccount}>
                    <p>
                      {/* {data?.username} */}
                      {data?.profileImage ? (
                        <img
                          className="profile-icon"
                          src={data?.profileImage}
                          alt="profile"
                        />
                      ) : (
                        <img
                          className="profile-icon"
                          src={ProfileIcon}
                          alt="profile"
                        />
                      )}
                    </p>
                  </li>
                )}
                <li onClick={gotocart}>
                  <p>
                    <img src={CartIcon} className="cart-icon" alt="cart" />
                  </p>
                </li>
                <li onClick={gotocart}>
                  <p className="count-of-cart-item-mobile">
                    {cartdata && cartdata.cartitem && cartdata.cartitem?.length}
                  </p>
                </li>

                <li onClick={() => navigate("/")}>
                  <p>Home</p>
                </li>
                <li onClick={() => navigate("/about-us")}>
                  <p>About</p>
                </li>

                {userid ? (
                  <>
                    <li onClick={logout}>
                      <p>Logout</p>
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={() => navigate("/signin")}>
                      <p>Sign In</p>
                    </li>
                    {/* <li onClick={() => navigate("/signup")}>
                      <p>Sign Up</p>
                    </li> */}
                  </>
                )}
              </ul>
            </div>
          ) : (
            <div className="navbar-links">
              <ul>
                <div className="desktop-navbar-wrapper">
                  <div>
                    <li onClick={() => navigate("/")}>
                      <p>Home</p>
                    </li>
                    <li onClick={() => navigate("/About-us")}>
                      <p>About</p>
                    </li>
                    <li className="hidden-contactus">
                      <p onClick={() => navigate("/contact-us")}>Contact Us</p>
                    </li>
                  </div>

                  <div>
                    {showSearchBar && (
                      <li>
                        <p>
                          <div className="search-box-wrapper">
                            <img src={SeachIcon} alt="search" />
                            <input
                              className="search-input"
                              type=""
                              placeholder="Search here"
                              value={searchInput}
                              onChange={handleInputChange}
                            />
                          </div>
                        </p>
                      </li>
                    )}

                    {!showSearchBar && (
                      <li>
                        <p>
                          <div className="blank-area">blank-area</div>
                        </p>
                      </li>
                    )}

                    {userid ? (
                      <>
                        {/* <li onClick={gotoaccount}><p>Account</p></li> */}

                        <li onClick={gotocart}>
                          {/* <p>Cart [ {cartdata.cartitem?.length} ]</p> */}
                          <p>
                            <img
                              src={CartIcon}
                              className="cart-icon"
                              alt="cart"
                            />
                            {/* <span className="count-of-cart-item">
                            {cartdata.cartitem?.length}
                            </span> */}
                          </p>
                        </li>
                        <li onClick={gotocart}>
                          {/* <p>Cart [ {cartdata.cartitem?.length} ]</p> */}

                          <p className="count-of-cart-item">
                            {cartdata &&
                              cartdata.cartitem &&
                              cartdata.cartitem?.length}
                          </p>
                        </li>
                        <li onClick={gotoaccount}>
                          <p>
                            {/* {data?.username} */}
                            {data?.profileImage ? (
                              <img
                                className="profile-icon"
                                src={data?.profileImage}
                                alt="profile"
                              />
                            ) : (
                              <img
                                className="profile-icon"
                                src={ProfileIcon}
                                alt="profile"
                              />
                            )}
                          </p>
                        </li>
                        {/* <li onClick={logout}>
                        <p>Logout</p>
                      </li> */}
                      </>
                    ) : (
                      <>
                        <li onClick={gotocart}>
                          {/* <p>Cart [ {cartdata.cartitem?.length} ]</p> */}
                          <p>
                            <img
                              src={CartIcon}
                              className="cart-icon"
                              alt="cart"
                            />{" "}
                            {/* {cartdata &&
                              cartdata.cartitem &&
                              cartdata.cartitem?.length} */}
                          </p>
                        </li>
                        <li onClick={gotocart}>
                          {/* <p>Cart [ {cartdata.cartitem?.length} ]</p> */}

                          <p className="count-of-cart-item">
                            {cartdata &&
                              cartdata.cartitem &&
                              cartdata.cartitem?.length}
                          </p>
                        </li>
                        <li onClick={() => navigate("/signin")}>
                          <p>Sign In</p>
                        </li>
                      </>
                    )}
                  </div>
                </div>
              </ul>
            </div>
          )}
        </nav>
      </div>

      {searchInput.length > 0 && (
        <div className="search-suggestions-wrapper">
          {filteredData.length > 0 ? (
            // Display filteredData if matches found
            filteredData.map((item) => (
              <div
                key={item._id}
                className="search-product-suggentions"
                onClick={(e) => getproductdetail(item._id)}
              >
                <img src={item.images[0]} alt="" />
                <p> {item.name}</p>
              </div>
            ))
          ) : (
            // Display "No Match Found" if no matches found
            <div className="search-product-suggentions">
              <p>No results found!</p>
            </div>
          )}
        </div>
      )}
      <div className="search-bar-mobile-wrapper">
        {showSearchBar && (
          <p>
            <div className="search-box-wrapper">
              <img src={SeachIcon} alt="search" />
              <input
                className="search-input"
                type=""
                placeholder="Search here"
                value={searchInput}
                onChange={handleInputChange}
              />
            </div>
          </p>
        )}
      </div>
      <div>
        {searchInput.length > 0 && (
          <div className="mobile-search-suggesstion-wrapper-main">
            {filteredData.length > 0 ? (
              // Display filteredData if matches found
              filteredData.map((item) => (
                <div
                  key={item._id}
                  className="search-product-suggentions-mobile"
                  onClick={(e) => getproductdetail(item._id)}
                >
                  <img src={item.images[0]} alt="" />
                  <p> {item.name}</p>
                </div>
              ))
            ) : (
              // Display "No Match Found" if no matches found
              <div className="search-product-suggentions-mobile">
                <p>No results found!</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default NavBar;