import React, { useState } from 'react';
import './AdsBanner.css';
import LoadingShimmer from '../loadingshimmer/LoadingShimmer';
const AddAdsBanner = ({addBanner}) => {

    const [url,setUrl] = useState();
    const [baseImage,setBaseImage] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    const uploadImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setBaseImage(base64);
      };

      const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };


      const addbanner = async () =>{
        setIsLoading(true);
         try {
           const newBanner = {
             url,
             button:false,
             image: baseImage
          };
          await addBanner(newBanner)
          setUrl('')
          setBaseImage('')
        } catch (error) {
           console.log(error);
         } finally {
          setIsLoading(false); // Set loading to false when the function finishes
         }
      };

  return (
    <>

    <div className='addadsbanner-container' >
       
    <div className='addadsbanner-box'>
    
        <div className='addadsbanner-box-title'>
            <h1>Add Ads</h1>
        </div>

        <div className='addadsbanner-box-data'>
            <text>Image 1</text>
            {baseImage && <img className='addadsbanner-img' src={baseImage} alt="image" />}
            <input type='file' onChange={(e)=> {uploadImage(e)}}/>
        </div>


        <div className='addadsbanner-box-data'>
            <text>Banner Url</text>
            <input onChange={(e)=> setUrl(e.target.value)} />
        </div>

        <div className='addadsbanner-box-button'>
            <button onClick={addbanner}>Add Ads</button>
        </div>
        
    </div>

 

</div>
   <div className="loading-shimmer-add-product">
   {isLoading && <div className="loading-shimmer">
     <LoadingShimmer/>
     </div>}
   </div>
   </>
  )
}

export default AddAdsBanner
