import React, { useEffect, useState } from 'react'
import './ordermng.css'
import { useNavigate } from 'react-router-dom'
import axios from '../../../axios';
import moment from 'moment';

const Ordermng = () => {


  const navigate = useNavigate();

  const [data,setData]= useState([])
  const [refresh,setRefresh] = useState(true)
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [showCancelBox, setShowCancelBox] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [error, setError] = useState('');

  useEffect(()=>{
    (async()=>{
      try{
        let url = '/api/admin/getallorder';
        if (fromDate && toDate) {
          url += `?from=${fromDate}&to=${toDate}`;
        }
        let res = await axios.get(url, {withCredentials:true});
      setData(res.data)
      }catch(error){
        console.log(error)
      }
    })()
  },[refresh, fromDate, toDate])

  const updatestatus =async(status,orderid)=>{
   try{
    if (status === 'Cancelled') {
      setSelectedOrderId(orderid);
      setShowCancelBox(true);
    } else {
     let res = await axios.post(`/api/admin/updateorderstatus/${orderid}`,{status},{withCredentials:true})
     console.log(res)
     setRefresh(!refresh)
    }
   }catch(error){
    console.log(error)
   }
  }

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleReasonChange = (event) => {
    setCancellationReason(event.target.value);
  };

  const handleSubmitCancellation = async () => {
    try {

      if (!cancellationReason.trim()) {
        setError('Please provide a reason for cancellation.');
        return;
      }


      let res = await axios.put(`/api/admin/cancelorder/${selectedOrderId}`, {
        reason: cancellationReason,
      },{withCredentials:true});
      if(res.status == 200){  
      setRefresh(!refresh);
      setShowCancelBox(false); 
      setCancellationReason('');
      } 
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseCancelBox = () => {
    setShowCancelBox(false);
    setCancellationReason('');
    setError('');
  };



  return (
    <div className='showorder-container'>
    <div className='showorder-box'>
      <h1 className='showorder-title'>ORDERS</h1>

      <div className='date-range-selectors'>
          <label>From:</label>
          <input type='date' value={fromDate} onChange={handleFromDateChange} />
          <label>To:</label>
          <input type='date' value={toDate} onChange={handleToDateChange} />
        </div>
    
<div className='showorder-divtable'>
<table className='showorder-table'>
<tr>
  <th>Date</th>
  <th>Order Id</th>
  <th>Payment Id</th>
  <th>Grand Total</th>
  <th>Order Detail</th>
  <th>Status</th>
</tr>

{data?.map ((item,index)=>(
  <tr>
  <td>{moment(item?.Orderdate).format('LL')}</td>
  <td>{item?._id}</td>
  <td>{item?.paymentid}</td>
  <td>{item?.total+item?.shippingCharge}</td>
  <td>
    <button onClick={()=>navigate(`/admin/orders/orderdetail/${item?._id}`)}>
      View Detail
    </button>
  </td>
  <td>
  {item?.orderstatus === "Deliverd" || item?.orderstatus === "Cancelled" ? <label>{item?.orderstatus}</label>  :<select  className='ordermng-select ' value={item?.orderstatus} onChange={(e)=> updatestatus(e.target.value,item?._id)}>
  <option >{item?.orderstatus}</option>
{item?.orderstatus === "Confirmed" && 
  <>
  <option value={"Shipped"} >Shipped</option>
  <option value={"Out For Delivery"}>Out For Delivery</option>
  <option value={"Deliverd"}>Deliverd</option>
  <option value={"Cancelled"}>Cancelled</option>
  </>
  }

  {item?.orderstatus === "Shipped" && 
  <>
  <option value={"Out For Delivery"}>Out For Delivery</option>
  <option value={"Deliverd"}>Deliverd</option>
  <option  value={"Cancelled"}>Cancelled</option>
  </>
  }
    {item?.orderstatus === "Out For Delivery" && 
  <>
  <option value={"Deliverd"}>Deliverd</option>
  <option  value={"Cancelled"}>Cancelled</option>
  </>
  }
  </select>}
  </td>
</tr>
))}

</table>
</div>

    </div>

    {showCancelBox && (
        <div className='showorder-overlay'>
          <div className='showorder-cancel-box'>
            <textarea
              className='showorder-cancel-textarea'
              placeholder='Write your reason for cancellation...'
              value={cancellationReason}
              onChange={handleReasonChange}
            />
            {error && <p className="error-message">{error}</p>}
            <div className='showorder-cancel-buttons'>
              <button onClick={handleSubmitCancellation}>Submit</button>
              <span className='showorder-cancel-close' onClick={handleCloseCancelBox}>
                &times;
              </span>
            </div>
          </div>
        </div>
      )}
      
  </div>
  )
}

export default Ordermng