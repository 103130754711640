import React, { useEffect, useState } from 'react'
import './addshippingaddress.css'
import Addressform from './addressform/Addressform'
import { useNavigate } from 'react-router-dom'
import axios from '../../axios';

const Addshippingaddress = () => {

    const navigate = useNavigate();

    const [cartdata,setCartdata] = useState([])
    const [grandtotal,setGrandtotal] = useState();
    const [deliveryCharge, setDeliveryCharge] = useState("");

    const userid = localStorage.getItem("userid");
 
    function gotoshipping(){
        navigate('/shipping')
    }

    useEffect(()=>{ 
        (async()=>{
            try{
                let res = await axios.get(`/api/getcartitem/${userid}`, {
                    withCredentials: true,
                  });
                setCartdata(res.data.cartitem)
                setGrandtotal(res.data.grandtotal)
               }catch(error){
                console.log(error)
               }
        })()
    },[])


    useEffect(() => {
        (async () => {
          try {
            const deliveryChargeRes = await axios.get(`/api/getalldeliverycharge`, {
              withCredentials: true,
            });
    
            // Extract delivery charge data from the response
            const deliveryCharges = deliveryChargeRes.data;
    
            // Sort delivery charges by priceLesser value
            deliveryCharges.sort((a, b) => a.priceLesser - b.priceLesser);
    
            // Find the applicable delivery charge based on grand total
            let applicableCharge = null; // Default charge
    
            for (let i = 0; i < deliveryCharges.length; i++) {
              const charge = deliveryCharges[i];
              if (
                cartdata.grandtotal >= charge.priceGreater &&
                cartdata.grandtotal <= charge.priceLesser
              ) {
                applicableCharge = charge.charge;
                break;
              }
            }
    
            // Set the delivery charge to the applicable charge
            setDeliveryCharge((prevCharge) => {
              if (prevCharge !== applicableCharge) {
                return applicableCharge;
              }
              return prevCharge;
            });
          } catch (error) {
            console.log(error);
          }
        })();
      }, [cartdata.grandtotal]);

  return (
    <div className='addshippinaddress-container'>
<div className='addshippinaddress-left-container'>
<Addressform type="shipping" />

</div>

<div className='addshippinaddress-right-container'>
    
    <p className='addshippinaddress-box-total-title'>Order Details</p>
    <div className='addshippinaddress-box-total-detail'>
        <p>Price</p>
        <p>₹{grandtotal}</p>
    </div>
    <div className='addshippinaddress-box-total-detail'>
        <p>Items</p>
        <p>{cartdata.length}</p>
    </div>
    <div className='addshippinaddress-box-total-detail'>
        <p>Delivery Charges</p>
        {deliveryCharge ? <p>₹{deliveryCharge}</p> : <p>Free</p>}
    </div>
    
    <div className='addshippinaddress-box-total-detail-amount'>
        <p>Total Amount</p>
        <p>₹{grandtotal+deliveryCharge}</p>
    </div>
    
    <div className='addshippinaddress-box-total-detail-btn'>
       <button onClick={gotoshipping}>PROCEED TO PAYMENT</button>
                </div>
          </div>

    </div>
  )
}

export default Addshippingaddress
