import React, { useState } from "react";
import "./footer.css";
import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Pinterest,
  Room,
  Twitter,
  WhatsApp,
  YouTube,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/Inkista-logo.png";
import LogoFooter from "../../assets/logo/inkista-footer-logo.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="Footer-Container">
        <div className="Footer-LeftContainer">
          <img className="logo-image-footer" src={LogoFooter} alt="MyInkista" />

          <p className="Footer-Desc">
            Our mission is to ignite creativity, foster self expression, and
            promote mindfulness through our thoughtfully curated products.
          </p>

          <div className="Footer-SocialContainer">
            {/* <div className="Footer-SocialIcon">
            <Facebook />
          </div> */}
            <div className="Footer-SocialIcon">
              <a
                href="https://www.instagram.com/inkista.in?igsh=MWtybXZ1em42NGQ2Zg%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
            </div>
            <div className="Footer-SocialIcon">
              <YouTube />
            </div>
            {/* <div className="Footer-SocialIcon">
            <Twitter />
          </div> */}
            {/* <div className="Footer-SocialIcon">
            <Pinterest />
          </div> */}
          </div>
        </div>

        <div className="Footer-CenterContainer">
          {/* <h5 className="Footer-Title">Useful List</h5> */}
          <ul className="Footer-List">
            <li className="Footer-ListItem" onClick={() => navigate("/")}>
              Home
            </li>
            <li className="Footer-ListItem" onClick={() => navigate("/cart")}>
              Cart
            </li>
            {/* <li className="Footer-ListItem">Men Fashion</li>
          <li className="Footer-ListItem">Women Fashion</li> */}
            {/* <li className="Footer-ListItem">Accessories</li> */}
            <li
              className="Footer-ListItem"
              onClick={() => navigate("/accounts/profile")}
            >
              My Account
            </li>
            <li
              className="Footer-ListItem"
              onClick={() => navigate("/accounts/orders")}
            >
              Orders
            </li>
            <li
              className="Footer-ListItem"
              onClick={() => navigate("/about-us")}
            >
              <p>About</p>
            </li>
          </ul>
        </div>

        <div className="Footer-RightContainer">
          {/* <h5 className="Footer-Title">Contact</h5> */}
          <div className="Footer-ContactItem">
            <Room style={{ marginRight: "10px" }} />
            Kerala, India
          </div>
          <div
            className="Footer-ContactItem"
            // onClick={() => navigate("/about-us")}
          >
            <WhatsApp style={{ marginRight: "10px" }} />{" "}
            <a
              href="https://wa.me/919847660300"
              target="_blank"
              className="contact-number-whatsapp-redirection"
            >
              +91 9847660300
            </a>
          </div>
          <div className="Footer-ContactItem">
            <MailOutline style={{ marginRight: "10px" }} /> myinkista@gmail.com
          </div>
          {/* <img
            className="Footer-Payment"
            src="https://i.ibb.co/Qfvn4z6/payment.png"
            alt="payment"
          /> */}
        </div>
      </div>
      <div className="contact-us-and-others-and-copyright">
        <div className="contact-us-and-others-wrapper">
          <p
            className="footer-contactus-and-all-links"
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </p>
          <p
            className="footer-contactus-and-all-links"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p
            className="footer-contactus-and-all-links"
            onClick={() => navigate("/terms-and-conditions")}
          >
            Terms And Conditions
          </p>
          <p
            className="footer-contactus-and-all-links"
            onClick={() => navigate("/shipping-and-return-policy")}
          >
            Shipping and Return Policy
          </p>
        </div>
      </div>
      <div className="copy-right-wrapper">
        <span onClick={() => navigate("/")}>© 2024, Inkista.</span>
      </div>
    </>
  );
};

export default Footer;
