import React from 'react'
import Navbar from '../../component/admin/navbar/Navbar'
import Showproducts from '../../component/admin/productmng/Showproduct'

const Showproduct = () => {
  return (
    <div>
        <Navbar />
        <Showproducts />
    </div>
  )
}

export default Showproduct