import React from 'react'
import Addshippingaddresss from '../component/shipping/Addshippingaddress'
import NavBar from '../component/navbar/NavBar'
import Footer from '../component/footer/Footer'

const Addshippingaddress = () => {
  return (
    <div>
      <NavBar />
      <Addshippingaddresss />
      <Footer />
    </div>
  )
}

export default Addshippingaddress
