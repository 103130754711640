import React, { useEffect, useState } from "react";
import "./Checkout.css";
import { useNavigate, useParams } from "react-router-dom";
import PaymentSuccess from "../../assets/success-page/payment_success.png";
import Logo from "../../assets/logo/logo.jpeg";
import DownloadIcon from "../../assets/success-page/donwload-icon.png";
import axios from "../../axios";
import html2pdf from "html2pdf.js";
// import './orderdetail.css'
// import { trackMetaPixelEvent } from "../../tracking/FacebookPixelEvent";


const CheckoutSucess = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState();

  const userid = localStorage.getItem("userid");

  useEffect(() => {
    // trackMetaPixelEvent('paymentSuccess', { button: 'payment success' });

    (async () => {
      try {
        if (userid) {
        let res = await axios.get(`/api/order/detail/${params.orderid}`, {
          withCredentials: true,
        });
        console.log(res)
        setData(res.data);
      }else{
        let res = await axios.get(`/api/order/detail/guest/${params.orderid}`);
        setData(res.data);
      }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [params.orderid]);



  const handleDownloadInvoice = () => {
    const element = document.getElementById("invoice");
    html2pdf(element, {
      margin: 1,
      filename: `invoice_${data?.orderid}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
  };

  return (
    <>
      <div className="success-main-wrapper">
        <div className="success-left-wrapper">
          <div className="checksucces-maincontainer">
            <div className="checksucces-container">
              <img src={PaymentSuccess} />

              <p className="order-confirmed">Your Order is confirmed</p>
              <h3>Thanks for shopping with us</h3>
              <button onClick={() => navigate("/")}>BACK TO SHOPPING</button>
            </div>
            <div className="inkista-logo-and-text-wrapper">
              <img src={Logo} alt="logo" />
              <p>
                “A book is a gift You can open again and again”{" "}
                <span>-Garrison Keillor</span>
              </p>
            </div>
          </div>
        </div>

        <div className="success-right-wrapper">
          <div className="invoicewrapper" id="invoice">
            <div className="order-id-with-status-wrapper">
              <div className="order-details-text-and-id">
                <p className="order-details-text">ORDER DETAILS</p>
                <p className="order-id">{data?._id}</p>
              </div>
              <div className="payment-status-text">
                <p>{data?.orderstatus}</p>
              </div>
            </div>
            <div className="order-id-with-status-wrapper">
              <div className="order-details-text-and-id" id="all-table">
                <p className="order-details-text">Delivery Address</p>
                <p className="full-address">
                  {data?.address?.locality},{data?.address?.buildingname},
                  {data?.address?.landmark},{data?.address?.district},
                  {data?.address?.state}
                </p>
              </div>
            </div>
            <div className="order-id-with-status-wrapper">
              <div className="order-details-text-and-id">
                <p className="order-details-text">Contact Details</p>
                <p className="full-address">{data?.address?.mobile}</p>
                <p className="full-address">{data?.address?.name}</p>
                <p className="full-address">{data?.address?.pin}</p>

                {/* <p className="full-address">temp@gmail.com</p> */}
              </div>
            </div>
            <div className="order-id-with-status-wrapper">
              <div className="order-details-text-and-id">
                <p className="order-details-text">Order Summary</p>
                <div className="item-qty-price-table">
                  <div className="titles-item-qty-price table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Qty</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.products?.map((item) => (
                          <tr>
                            <td className="item-name-table">
                              {item?.itemname}
                            </td>
                            <td className="item-qty-table">{item?.qty}</td>
                            <td className="item-price-table">
                              {item?.subtotal}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td className="item-name-table">Sub Total</td>
                          <td></td>
                          <td className="item-name-table">{data?.total}</td>
                        </tr>
                        <tr>
                          <td className="item-name-table">Delivery</td>
                          <td></td>
                          {data?.shippingCharge ? <td className="item-name-table">
                            {" "}
                            {data?.shippingCharge}
                          </td>
                          :<td className="item-name-table">
                            {" "}
                            Free
                          </td>}
                        </tr>
                        <tr>
                          <td className="total-am">Total</td>
                          <td></td>
                          <td>{data?.total + data?.shippingCharge}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="invoice-donwload">
            <button onClick={handleDownloadInvoice}>
              <img src={DownloadIcon} alt="" /> Download Invoice
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutSucess;
