import { Container } from '@mui/material'
import React from 'react'

const EmptyCart = () => {
  return (
    <Container sx={{minHeight:'80vh'}}> 



    <iframe    width= "100%"
    height= "500" src="https://lottie.host/?file=7de93e6b-cdef-473b-b4c9-571fc6551096/xKjGfgQkPx.json"></iframe>
    
   </Container>  
  )
}

export default EmptyCart
